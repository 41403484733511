import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Api, Session} from '../const'
import {convertViewPointPx} from '../const/common'
import {join, map, find} from 'lodash-es'
import {Icons, Button} from '../components'
import {Menu} from '../structure'
import {useNavigate, matchPath} from 'react-router-dom'

import InupsLogo from '../assets/image/logo/inups.png'
import InupsWhiteLogo from '../assets/image/logo/inups-white.png'
import Colors from '../assets/theme/colors'

const HeaderContainer = styled.div`
${(props) =>
  css`
    background: ${props.background};
  `}
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${join(
    map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
    ' ',
  )} rgba(0, 0, 0, 0.08);

  @media all and (min-width: 641px) {
    z-index: 2;
    height: ${convertViewPointPx(90)}px;
  }
  @media all and (max-width: 640px) {
    z-index: 998;
    height:${convertViewPointPx(72)}px;
  }
}
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (min-width: 641px) {
    // 고정
    // width: 1180px;
    width: 61.5%;
    height: 100%;
    & img {
      width: ${convertViewPointPx(83)}px;
      cursor: pointer;
    }
    & .grid {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gird-tem: flex-end;
      grid-column-gap: ${convertViewPointPx(88)}px;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  @media all and (max-width: 640px) {
    width: 100%;
    padding: 0 ${convertViewPointPx(24)}px;
    & img {
      width: ${convertViewPointPx(55)}px;
    }
  }
`
const MenuWrap = styled.div`
  display: flex;
  height: 100%;
  & div.menu {
    position: relative;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${convertViewPointPx(48)}px;
    font-weight: 700;
    font-size: ${convertViewPointPx(16)}px;
    line-height: ${convertViewPointPx(19)}px;
    color: ${(props) => props.color};

    &:last-child {
      margin-right: 0;
    }

    & a {
      display: block;
      text-decoration: none;
      &.active {
        color: ${Colors.primary} !important;
      }
    }

    & .childsWrap {
      display: none;
      position: absolute;
      min-width: ${convertViewPointPx(160)}px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);

      &.hover {
        display: block;
      }
    }

    & .childs {
      margin-top: ${convertViewPointPx(8)}px;
      background: #ffffff;
      box-shadow: ${join(
          map([0, 4, 16], (i) => convertViewPointPx(i) + 'px'),
          ' ',
        )}
        rgb(0 0 0 / 8%);
      border-radius: ${convertViewPointPx(8)}px;
      color: ${Colors.black};
      padding: ${join(
        map([18, 24], (i) => convertViewPointPx(i) + 'px'),
        ' ',
      )};

      & a {
        height: ${convertViewPointPx(54)}px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
`
const MobileMenuWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  background: #ffffff;
  z-index: 999;
  width: 0;
  height: 0;

  overflow: hidden;

  ${(props) =>
    props.open
      ? css`
          width: 100vw;
          height: 100vh;
        `
      : css`
          width: 0;
          height: 0;
        `}

  & .header {
    display: flex;
    justify-content: space-between;
    padding: 0 ${convertViewPointPx(24)}px;
    height: ${convertViewPointPx(72)}px;
    align-items: center;
  }

  & .menu {
    width: 100%;
    padding: 0 ${convertViewPointPx(24)}px;
    min-height: ${convertViewPointPx(56)}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: ${convertViewPointPx(16)}px;
    line-height: ${convertViewPointPx(19)}px;
    color: ${Colors.black};

    & .menuBtn {
      display: flex;
      align-items: center;
      height: ${convertViewPointPx(56)}px;
      justify-content: space-between;
      width: 100%;
      & a.active {
        color: ${Colors.primary};
      }
    }
    & .childs {
      display: none;
      &.hover {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      & a {
        height: ${convertViewPointPx(50)}px;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: ${convertViewPointPx(14)}px;
        &.active {
          color: ${Colors.primary};
        }
      }
    }
  }
`

// 왼쪽으로 열리는 에니메이션
// ${(props) =>
//   props.open
//     ? 'animation: show ease-out 0.5s forwards;'
//     : 'animation: hide ease-out 0.5s forwards;'}

// @keyframes show {
//   0% {
//     opacity: 0;
//     transform: translateX(100vw);
//   }

//   100% {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }

// @keyframes hide {
//   0% {
//     opacity: 0;
//     transform: translateX(0);
//   }

//   100% {
//     opacity: 0;
//     transform: translateX(100vw);
//   }
// }

const MobileMenuBtn = styled.div`
  width: ${convertViewPointPx(24)}px;
  height: ${convertViewPointPx(24)}px;
  cursor: pointer;
`

const ButtonWrap = styled.div`
  display: flex;
  & > button {
    margin-right: 12px;
  }

  & > button:last-child {
    margin-right: 0;
    min-width: 150px;
  }
`

/**
 *
 * @param {width, height, margin([mt,mr,mb,ml])} props
 * @returns
 */
function Header(props) {
  const navigate = useNavigate()

  const {isMobile, pathname, isScroll, style} = props
  const {userMenu, adminMenu, noUserMenu} = Menu

  const [isUserPage, setIsUserPage] = useState(false)
  const [background, setBackground] = useState('#ffffff')
  const [hoverMenu, setHoverMenu] = useState(null)
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)
  const [menuList, setMenuList] = useState(userMenu)

  const handleOnClickMenu = (menu) => {
    if (menu.path && !menu.children) {
      navigate(menu.path)
      setIsOpenMobileMenu(null)
    } else {
      if (isMobile && menu.children) {
        if (hoverMenu && hoverMenu.id === menu.id) {
          setHoverMenu(null)
        } else {
          setHoverMenu(menu)
        }
      }
    }
  }

  useEffect(() => {
    const user = Session.get('User')
    if (user?.roleId === 1 && isMobile === false) {
      setMenuList([...userMenu, ...adminMenu])
    }
  }, [])

  useEffect(() => {
    setIsUserPage(pathname.indexOf('user') > -1)
  }, [pathname])

  useEffect(() => {
    if (isMobile && isUserPage) {
      setBackground('transparent')
    } else {
      if (pathname === '/') {
        if (isScroll) {
          setBackground('#fff')
        } else {
          setBackground('transparent')
        }
      }
    }
  }, [pathname, isUserPage, isMobile, isScroll])

  const makesParamUrl = (params) => {
    return join(
      map(params || [], (param) => `/:${param}`),
      '',
    )
  }

  const renderMenu = (pathname) => {
    return map(isUserPage ? menuList : noUserMenu, (menu, index) => {
      let findActiveChild = false
      if (menu.children) {
        findActiveChild = find(menu.children, (item) =>
          matchPath({path: item.path + makesParamUrl(item.params)}, pathname),
        )
      }
      const active =
        matchPath({path: menu.path + makesParamUrl(menu.params)}, pathname) || findActiveChild

      return (
        <div
          className="menu"
          key={index}
          onMouseEnter={() => setHoverMenu(menu)}
          onMouseLeave={() => setHoverMenu(null)}>
          <a className={active ? 'active' : ''} onClick={() => handleOnClickMenu(menu)}>
            {menu.name}
          </a>
          {menu.children && (
            <div className={`childsWrap ${hoverMenu && hoverMenu.id === menu.id ? 'hover' : ''}`}>
              <div className="childs" key={index}>
                {map(menu.children, (childMenu, index) => {
                  return (
                    <a
                      className={findActiveChild?.id === childMenu.id ? 'active' : ''}
                      key={index}
                      onClick={() => handleOnClickMenu(childMenu)}>
                      {childMenu.name}
                    </a>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )
    })
  }

  const renderMobileMenu = (pathname) => {
    return map(
      isUserPage
        ? menuList
        : noUserMenu.concat([{id: '2', name: '통합로그인 바로가기', path: '/login'}]),
      (menu, index) => {
        const findActiveChild = menu.children ? find(menu.children, {path: pathname}) : false
        const active = pathname === menu.path || findActiveChild

        return (
          <div className="menu" key={index} onClick={() => handleOnClickMenu(menu)}>
            <div className="menuBtn">
              <a className={active ? 'active' : ''}>{menu.name}</a>
              <Icons
                id="arrowRight"
                transform={hoverMenu && hoverMenu.id === menu.id ? 'rotate(90deg)' : 'rotate(0)'}
              />
            </div>
            {menu.children && (
              <div
                className={`childs ${hoverMenu && hoverMenu.id === menu.id ? 'hover' : ''}`}
                key={index}>
                {map(menu.children, (childMenu, index) => {
                  return (
                    <a
                      className={findActiveChild?.id === childMenu.id ? 'active' : ''}
                      key={index}
                      onClick={() => handleOnClickMenu(childMenu)}>
                      {childMenu.name}
                    </a>
                  )
                })}
              </div>
            )}
          </div>
        )
      },
    )
  }

  const logout = () => {
    Api.getInstance()
      .get(Api.url + '/user/logout')
      .then((res) => {
        alert('로그아웃 되었습니다.')
        navigate('/logout')
      })
      .catch((e) => {
        alert('로그아웃 되었습니다.')
        navigate('/logout')
      })
  }

  return (
    <HeaderContainer background={background} style={{...style}}>
      <HeaderWrap>
        <img
          onClick={() => navigate('/')}
          src={pathname === '/' && !isScroll ? InupsWhiteLogo : InupsLogo}
        />
        <div className="grid">
          {isMobile ? (
            <div>
              <MobileMenuBtn onClick={() => setIsOpenMobileMenu(true)}>
                <Icons
                  id="menu"
                  color={pathname === '/' && !isScroll ? '#ffffff' : '#1A1A1A'}
                  width={convertViewPointPx(24)}
                  height={convertViewPointPx(24)}
                  vWidth={24}
                  vHeight={24}
                />
              </MobileMenuBtn>
              <MobileMenuWrap open={isOpenMobileMenu} color={Colors.black}>
                <div className="header">
                  <img src={InupsLogo} />
                  <MobileMenuBtn onClick={() => setIsOpenMobileMenu(false)}>
                    <Icons
                      id="close"
                      width={convertViewPointPx(24)}
                      height={convertViewPointPx(24)}
                      vWidth={24}
                      vHeight={24}
                    />
                  </MobileMenuBtn>
                </div>
                {renderMobileMenu(pathname)}
              </MobileMenuWrap>
            </div>
          ) : (
            <>
              <MenuWrap
                color={pathname === '/' && !isScroll ? '#ffffff' : Colors.black}
                isScroll={isScroll}>
                {renderMenu(pathname)}
              </MenuWrap>
              {isUserPage ? (
                <ButtonWrap>
                  <Button secondary height={44} onClick={logout}>
                    로그아웃
                  </Button>
                  {/* <Button primary height={44} onClick={() => navigate('/')}>
                    홈페이지 바로가기
                  </Button> */}
                </ButtonWrap>
              ) : (
                <Button primary height={44} onClick={() => {
                  window.open('/login')
                }}>
                  서비스 이동하기
                </Button>
              )}
            </>
          )}
        </div>
      </HeaderWrap>
    </HeaderContainer>
  )
}

Header.propTypes = {
  children: PropTypes.any,
  isMobile: PropTypes.bool,
  pathname: PropTypes.any,
  isScroll: PropTypes.number,
  style: PropTypes.any,
}

export default Header
