import axios from 'axios'
import jwt from 'jsonwebtoken'
import {Session} from './session'

export class Api {
  static instance = null
  static url = 'https://ssoapi.inups.kr'

  constructor() {
    this.session = axios.create()
  }

  static getInstance() {
    if (Api.instance === null) {
      Api.instance = new Api()
    }

    if (!this.token) {
      Api.instance.getToken()
      Api.instance.setToken()
    }

    Api.instance.validation()

    return Api.instance
  }

  validation = () => {
    try {
      const auth = jwt.decode(this.token)
      const condition = !auth.exp || Date.now() < auth.exp * 1000
      if (!condition) {
        const url = window.location.href
        if (url.indexOf('/help') === -1) {
          if (url.indexOf('/popup') > -1) {
            if (window) window.close()
          } else {
            window.location = '/logout'
          }
        }
      }
    } catch (e) {}
  }

  validateStatus = (callback) => {
    callback.catch((error) => {
      if (this.getToken()) {
        if (error.response?.status === 401) {
          const errorResponse = error.response.data.data
          window.location = `/logout?code=${errorResponse.errorCode}`
        }
      }
    })
    return callback
  }

  getToken = () => {
    this.token = Session.get('Token')
    return !!this.token
  }

  setToken = (token) => {
    if (token) {
      this.token = token
    }

    if (this.token) {
      this.session.defaults.headers.common.Authorization = `Bearer ${this.token}`
    } else {
      delete this.session.defaults.headers.common.Authorization
    }
  }

  getUser = () => {
    if (this.token) {
      const user = jwt.decode(this.token)
      return user
    }
    return null
  }

  get = (...params) => this.validateStatus(this.session.get(...params))
  post = (...params) => this.validateStatus(this.session.post(...params))
  put = (...params) => this.validateStatus(this.session.put(...params))
  patch = (...params) => this.validateStatus(this.session.patch(...params))
  delete = (...params) => this.validateStatus(this.session.delete(...params))
}
