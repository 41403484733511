import React, {useEffect, useState, useMemo} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  filter,
  map,
  ceil,
  find,
  orderBy,
  range,
  keys,
  slice,
  take,
  value,
  mapValues,
  unionBy,
  join,
} from 'lodash-es'
import Colors from '../assets/theme/colors'
import {convertViewPointPx} from '../const/common'
import {Icons, Button, Input, Dropbox} from '../components'

// import {convertViewPointPx} from '../const/common'

const TableContainer = styled.div`
  width: ${(props) => props.width || '100%'};
`

const CsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: ${convertViewPointPx(20)}px;
  overflow: hidden;
  position: relative;
  box-shadow: ${join(
      map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )}
    rgba(0, 0, 0, 0.08);

  tr,
  th,
  td {
    height: ${convertViewPointPx(63)}px;
    font-size: ${convertViewPointPx(14)}px;
    box-sizing: border-box;
    background-color: white;
    cursor: default;
  }

  th {
    position: relative;
    text-align: center;
    padding: 0 ${convertViewPointPx(8)}px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    color: rgba(51, 51, 51, 0.4);

    .tagWrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span.tag {
      cursor: pointer;
      padding: ${convertViewPointPx(8)}px;
      border-radius: ${convertViewPointPx(6)}px;
      justify-content: center;
      align-items: center;
    }

    &.filtering {
      span.tag {
        background: ${Colors.light_blue};
        color: rgba(51, 51, 51, 0.5);
      }
    }

    &:last-child > .filterContainer {
      left: -50%;
    }
  }

  tr {
    border-collapse: collapse;
    width: 100%;
    padding: 0 ${convertViewPointPx(8)}px;
    text-align: center;
    color: ${Colors.black};
    &:hover td {
      background-color: ${Colors.light_blue} !important;
    }

    &.event-cursor td {
      cursor: pointer;
    }
  }

  td.center {
    text-align: center;
  }
  td.right {
    text-align: right;
  }
  td {
    text-align: left;
    padding: 0 ${convertViewPointPx(8)}px;
    button {
      padding: 0;
    }
  }
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  .paginationWrap {
    margin-top: ${convertViewPointPx(20)}px;
    background: #ffffff;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: ${convertViewPointPx(10)}px;
    display: flex;
    overflow: hidden;
  }
`

const PaginationButton = styled.button`
  cursor: pointer;
  width: ${convertViewPointPx(40)}px;
  height: ${convertViewPointPx(40)}px;
  outline: none;
  border: none;
  font-size: ${convertViewPointPx(14)}px;
  background: white;
  font-weight: 400;
  color: ${Colors.black};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    border-radius: ${convertViewPointPx(8)}px;
    width: ${convertViewPointPx(30)}px;
    height: ${convertViewPointPx(30)}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    span {
      font-weight: 600;
      color: white;
      background: ${Colors.primary} !important;
    }
  }
  &:hover {
    span {
      background: ${Colors.light_blue};
    }
  }
`

const FilterContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 100%;
  background: #ffffff;
  border: 2px solid rgba(51, 51, 51, 0.1);
  box-shadow: ${join(
      map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )}
    rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  box-sizing: border-box;
  display: ${(props) => (props.disabled ? 'none' : 'block')};
  z-index: 2;

  .submitBtnsWrap {
    margin-top: ${convertViewPointPx(16)}px;
    display: flex;
    justify-content: space-between;
    height: 62px;
    align-items: center;
    border-top: 1px;
    border-top: 1px solid rgba(51, 51, 51, 0.1);
    padding: 0 16px;
    button {
      padding: 0 !important;
    }
  }
`

const Filter = styled.div`
  padding: ${convertViewPointPx(16)}px;
  span.filterType {
    cursor: default;
    font-weight: 600;
    font-size: ${convertViewPointPx(14)}px;
    line-height: ${convertViewPointPx(17)}px;
    color: #565656;
    text-align: left;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
`
function Table(props) {
  // primaryKey
  const {width, headers, data, maxRow, clickRow, primaryKey, hasPagination} = props
  const [initData, setInitData] = useState([])
  const [pagedList, setPagedList] = useState([])
  const [pageData, setPageData] = useState({})
  const [totalPageSize, setTotalPageSize] = useState(1)
  const [filterData, setFilterData] = useState(null)

  // 한 키만 들어갈 수 있으며 key 값을 가지고 있어서 필터 리셋 시킬때 사용한다
  const [sortKey, setSortKey] = useState(null) // {key,type}
  const [searchKey, setSearchKey] = useState(null) // {key,value}
  const [searchDropboxKey, setSearchDropboxKey] = useState(null) // {key,uten}

  // key: '',
  // sortType: null,
  // filterType: '',
  // searchValue: '',
  // searchDropboxItem: '',

  // 하나만 열리게 하기위해서
  const handleOpenFilter = async (key) => {
    const getConvertedData = (data) => {
      return new Promise(function (resolve, reject) {
        const convertData = {}
        if (data) {
          map(keys(data), (filterKey) => {
            convertData[filterKey] = {
              ...data[filterKey],
              sortType: sortKey?.key === key ? sortKey.type : null,
              searchValue: searchKey?.key === key ? searchKey.value : null,
              searchDropboxItem: searchDropboxKey?.key === key ? searchDropboxKey.item : null,
              isOpen: filterKey === key,
            }
          })
        }

        if (!data?.[key]) {
          convertData[key] = {isOpen: true}
        }

        resolve(convertData)
      })
    }

    const convertData = await getConvertedData(filterData)
    setFilterData(convertData)
  }

  const handleFilterData = (key, value) => {
    setFilterData({...filterData, [key]: {...filterData[key], ...value}})
  }

  const handleSubmitFilter = async (key, isReset = false) => {
    if (isReset) {
      setFilterData({
        ...filterData,
        [key]: {
          ...filterData[key],
          sortType: null,
          searchValue: null,
          searchDropboxItem: null,
        },
      })
    } else {
      if (filterData) {
        const keyData = filterData[key]

        const convertData = {}
        map(keys(filterData), (filterKey) => {
          if (filterKey === key) {
            convertData[filterKey] = {...filterData[filterKey], isOpen: false}
          } else {
            convertData[filterKey] = {
              ...filterData[filterKey],
              sortType: sortKey?.key === key ? sortKey.type : null,
              searchValue: searchKey?.key === key ? searchKey.value : null,
              searchDropboxItem: searchDropboxKey?.key === key ? searchDropboxKey.item : null,
              isOpen: false,
            }
          }
        })

        // 한 컬럼만 적용 ?
        // setSortKey(keyData.sortType ? {key, type: keyData.sortType} : null)
        // setSearchKey(keyData.searchValue ? {key, value: keyData.searchValue} : null)
        // 멀티 가능하게 ?
        if (keyData.sortType) {
          setSortKey({key, type: keyData.sortType})
        } else {
          if (!sortKey || sortKey.key === key) {
            setSortKey(null)
          }
        }

        if (keyData.searchValue) {
          setSearchKey({key, value: keyData.searchValue})
        } else {
          if (!searchKey || searchKey.key === key) {
            setSearchKey(null)
          }
        }

        if (keyData.searchDropboxItem) {
          setSearchDropboxKey({key, item: keyData.searchDropboxItem})
        } else {
          if (!searchDropboxKey || searchDropboxKey.key === key) {
            setSearchDropboxKey(null)
          }
        }
        setFilterData(convertData)
      }
    }
  }

  /**
   * paging_4. 페이징된 데이터 리턴
   * @param {Array} items
   * @returns
   */
  const paginate = (items) => {
    if (pageData) {
      let data = items

      // 글자검색
      if (searchKey) {
        data = filter(items, (item) => {
          const itemValue = item[searchKey.key]
          const value = itemValue && typeof itemValue === 'object' ? itemValue.value : itemValue
          if (value && value.indexOf(searchKey.value || '') > -1) {
            return true
          } else {
            return false
          }
        })
      }

      // 드롭박스 필터
      if (searchDropboxKey) {
        data = filter(data, (item) => {
          const itemValue = item[searchDropboxKey.key]
          const value = itemValue && typeof itemValue === 'object' ? itemValue.value : itemValue
          if (value === searchDropboxKey.item.name) {
            return true
          } else {
            return false
          }
        })
      }

      // 소팅
      if (sortKey) {
        const {key, type} = sortKey
        data = [
          ...orderBy(
            data,
            [
              (row) => {
                const col = row[key] && typeof row[key] === 'object' ? row[key].value : row[key]
                return col ? (isNaN(col) ? col : Number(col)) : ''
              },
            ],
            [type],
          ),
        ]
      }

      const newTotalPage = parseInt(ceil(data.length / maxRow))
      if (newTotalPage !== totalPageSize) {
        setTotalPageSize(newTotalPage)
      }

      const pageSize = pageData.pageSize
      // 자를 배열의 시작점
      const startIndex = (pageData.currentPage - 1) * pageSize

      const chainableFunctions = {
        slice,
        take,
        value,
      }

      const chainItems = (items) => {
        let value = items
        const wrapper = {
          ...mapValues(chainableFunctions, (f) => (...args) => {
            // lodash always puts input as the first argument
            value = f(value, ...args)
            return wrapper
          }),
          value: () => value,
        }
        return wrapper
      }

      return chainItems(data)
        .slice(startIndex) // 시작점부터 배열을 자르되
        .take(pageSize) // pageSize 만큰 배열을 가지고
        .value() // lodash wrapper 객체를 regular 배열로 변환
    } else {
      return items
    }
  }

  /**
   * 페이징 버튼 클릭
   * @param {'prev' | 'next'} type
   * @param {Event} e
   */
  const onClickPagination = (type, e, number = null) => {
    e.preventDefault()
    const totalPageGroupSize = parseInt(ceil(totalPageSize / 5))
    switch (type) {
      case 'prev':
        if (pageData.currentPage > 1) {
          setPagedList({
            ...pageData,
            currentPage: pageData.currentPage - 1,
          })
        }
        break
      case 'next':
        if (pageData.currentPage < totalPageSize) {
          setPagedList({
            ...pageData,
            currentPage: pageData.currentPage + 1,
          })
        }
        break
      case 'prevGroup':
        if (pageData.pageGroupCurrent > 1) {
          setPageData({
            ...pageData,
            currentPage: 5 * (pageData.pageGroupCurrent - 1),
            pageGroupCurrent: pageData.pageGroupCurrent - 1,
          })
        }
        break
      case 'nextGroup':
        if (pageData.pageGroupCurrent < totalPageGroupSize) {
          setPageData({
            ...pageData,
            currentPage: 5 * (pageData.pageGroupCurrent + 1 - 1) + 1,
            pageGroupCurrent: pageData.pageGroupCurrent + 1,
          })
        }
        break
      case 'number':
        setPageData({
          ...pageData,
          currentPage: number,
        })

        break
    }
  }

  useEffect(() => {
    setInitData(
      map(data, (item, index) => {
        return {...item, ...index}
      }),
    )
  }, [data])

  useEffect(() => {
    if (maxRow) {
      setPageData({
        pageSize: maxRow,
        currentPage: 1,
        pageGroupCurrent: 1,
      })

      setTotalPageSize(parseInt(ceil(initData.length / maxRow)))
    } else {
      setPageData(null)
    }
  }, [maxRow, initData])

  useEffect(() => {
    setPagedList(paginate(initData))
  }, [pageData, sortKey])

  useEffect(() => {
    setPageData({
      ...pageData,
      currentPage: 1,
      pageGroupCurrent: 1,
    })
  }, [searchKey, searchDropboxKey])

  const renderPagination = useMemo(() => {
    return (
      <PaginationContainer>
        <div className="paginationWrap">
          <PaginationButton
            className="arrow_button left_button"
            onClick={(e) => onClickPagination('prevGroup', e)}>
            <Icons hover id="arrowRight" transform="rotate(180deg)" />
          </PaginationButton>
          {map(range(1, 6, 1), (index) => {
            const page = index + (+pageData.pageGroupCurrent - 1) * 5
            if (page < totalPageSize + 1) {
              return (
                <PaginationButton
                  key={index}
                  value={page}
                  onClick={(e) => onClickPagination('number', e, page)}
                  className={+pageData.currentPage === page ? 'active' : ''}>
                  <span>{page}</span>
                </PaginationButton>
              )
            }
          })}
          <PaginationButton
            className="arrow_button right_button"
            onClick={(e) => onClickPagination('nextGroup', e)}>
            <Icons hover id="arrowRight" />
          </PaginationButton>
        </div>
      </PaginationContainer>
    )
  }, [totalPageSize, pageData])

  const renderFilter = (key, filterType, thisFilterData, options = []) => {
    return (
      <FilterContainer
        className='filterContainer'
        disabled={!thisFilterData?.isOpen}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        <Filter>
          <span className="filterType">정렬</span>
          <div style={{display: 'flex'}}>
            <Button
              primary={thisFilterData?.sortType === 'asc'}
              secondary={thisFilterData?.sortType !== 'asc'}
              width={98}
              height={32}
              fontSize={12}
              margin={[0, 16, 0, 0]}
              onClick={(e) => {
                handleFilterData(key, {
                  sortType: thisFilterData?.sortType === 'asc' ? null : 'asc',
                })
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Icons
                  id="sortUp"
                  hover
                  vWidth={16}
                  vHeight={16}
                  width={convertViewPointPx(16)}
                  height={convertViewPointPx(16)}
                  color={thisFilterData?.sortType === 'asc' ? '#ffffff' : Colors.primary}
                />
                오름차순
              </div>
            </Button>
            <Button
              primary={thisFilterData?.sortType === 'desc'}
              secondary={thisFilterData?.sortType !== 'desc'}
              width={98}
              height={32}
              fontSize={12}
              onClick={(e) => {
                handleFilterData(key, {
                  sortType: thisFilterData?.sortType === 'desc' ? null : 'desc',
                })
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Icons
                  id="sortDown"
                  hover
                  vWidth={16}
                  vHeight={16}
                  width={convertViewPointPx(16)}
                  height={convertViewPointPx(16)}
                  color={thisFilterData?.sortType === 'desc' ? '#ffffff' : Colors.primary}
                />
                내림차순
              </div>
            </Button>
          </div>
        </Filter>
        {filterType === 'select' ? (
          <Filter>
            <span className="filterType">검색 조건</span>
            <Dropbox
              height={40}
              fontSize={14}
              data={options}
              isSearch
              selected={thisFilterData?.searchDropboxItem || null}
              onChange={(item) => {
                handleFilterData(key, {
                  searchDropboxItem: item,
                })
              }}
              placeholder="선택해주세요."></Dropbox>
          </Filter>
        ) : (
          <Filter>
            <span className="filterType">검색</span>
            <Input
              line
              height={40}
              placeholder="검색어 입력"
              fontSize={14}
              onChange={(e) =>
                handleFilterData(key, {
                  searchValue: e.target.value,
                })
              }
              onKeyUp={(e) => {
                e.stopPropagation()
                if (e.keyCode === 13) {
                  handleSubmitFilter(key)
                }
              }}
              value={thisFilterData?.searchValue || ''}
            />
          </Filter>
        )}
        <div className="submitBtnsWrap">
          <Button
            secondary
            width={55}
            height={30}
            fontSize={12}
            onClick={(e) => handleSubmitFilter(key, true)}>
            초기화
          </Button>
          <Button
            primary
            width={55}
            height={30}
            fontSize={12}
            onClick={(e) => handleSubmitFilter(key)}>
            적용
          </Button>
        </div>
      </FilterContainer>
    )
  }

  const renderHeaders = useMemo(() => {
    const keyList = keys(data[0])
    const width = `${Math.floor(100 / headers.length)}%`
    return map(keyList, (key, index) => {
      const colData = find(headers, {key})
      const thisFilterData = filterData?.[key] || null
      let options = []
      if (colData?.filterType === 'select') {
        options = map(unionBy(data, key), (item, index) => {
          return {name: item[key], value: index}
        })
      }
      const isFiltering =
        sortKey?.key === key || searchKey?.key === key || searchDropboxKey?.key === key

      return (
        colData && (
          <th
            key={index}
            width={colData.width || width}
            id={'th_' + key}
            // className={thisFilterData?.isOpen ? 'filtering' : ''}
            className={isFiltering ? 'filtering' : ''}
            onClick={(e) => {
              if (colData.filterType !== 'none') {
                if (thisFilterData?.isOpen) {
                  handleFilterData(key, {isOpen: false})
                } else {
                  handleOpenFilter(key)
                }
              }
            }}>
            <div className="tagWrap">
              <span className="tag">
                {(colData && colData.title) || key}
                {colData.filterType !== 'none' && (
                  <Icons
                    hover
                    id="filter"
                    vWidth={18}
                    vHeight={18}
                    width={convertViewPointPx(18)}
                    height={convertViewPointPx(18)}
                    margin={`0 0 0 ${convertViewPointPx(6)}px`}
                    color={isFiltering ? Colors.primary : null}
                  />
                )}
              </span>
            </div>
            {/* {name: 'a', value: 0},
                {name: 'b', value: 1}, */}
            {/* 필터 */}
            {renderFilter(key, colData.filterType, thisFilterData, options)}
          </th>
        )
      )
    })
  }, [headers, data, sortKey, filterData, handleFilterData])

  const renderRows = useMemo(() => {
    if (headers && pagedList) {
      const width = `${Math.floor(100 / headers.length)}%`

      const dataTr = map(pagedList, (row, trIndex) => {
        const keyList = keys(row)
        const rowId =
          typeof row[primaryKey || 'index'] === 'object'
            ? row[primaryKey || 'index'].value
            : row[primaryKey || 'index']

        return (
          <tr
            id={`tr_${rowId}`}
            key={`tr_${rowId}`}
            className={clickRow ? 'event-cursor' : ''}
            onClick={(e) => clickRow && clickRow(rowId)}>
            {map(keyList, (key, tdIndex) => {
              const colData = find(headers, {key})
              const value = row[key] && typeof row[key] === 'object' ? row[key].value : row[key]
              const color = row[key] && typeof row[key] === 'object' ? row[key].color : null

              if (colData) {
                return (
                  <td
                    key={tdIndex}
                    width={colData.width || width}
                    className={colData.align || 'left'}
                    style={{color: color || ''}}>
                    {value || '-'}
                  </td>
                )
              }
            })}
          </tr>
        )
      })

      const emptyRowKeys = keys(initData[0])
      const emptyTr = map(range(maxRow - pagedList.length, 0, -1), (row, trIndex) => {
        return (
          <tr id={`empty_row_${row}`} key={`empty_row_${row}`}>
            {map(emptyRowKeys, (key, tdKey) => {
              const colData = find(headers, {key})
              if (colData) {
                return (
                  <td
                    key={tdKey}
                    width={colData.width || width}
                    className={colData.align || 'left'}></td>
                )
              }
            })}
          </tr>
        )
      })
      return [...dataTr, ...emptyTr]
    }
  }, [headers, pagedList])

  return (
    <TableContainer>
      <CsTable width={width}>
        <thead>
          <tr className="_height">{renderHeaders}</tr>
        </thead>
        <tbody className="tbody">{renderRows}</tbody>
      </CsTable>
      {hasPagination && renderPagination}
    </TableContainer>
  )
}

Table.propTypes = {
  width: PropTypes.number,
  headers: PropTypes.array,
  data: PropTypes.array,
  maxRow: PropTypes.number,
  hasPagination: PropTypes.bool,
  primaryKey: PropTypes.string,
  clickRow: PropTypes.func,
}

export default Table
