import React, {useEffect, useState, useMemo} from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import Colors from '../assets/theme/colors'
import {Table, Input, Button, Dropbox, Checkbox} from '../components'
import {Api} from '../const'
import {convertViewPointPx, convertCallNum} from '../const/common'
import {User} from '../structure'
import {map, join} from 'lodash-es'
import md5 from 'md5'

const Container = styled(Styles.Container)`
  flex-direction: column;
  width: 100%;
  min-width: 1200px;
  margin: 0;
  padding: ${convertViewPointPx(24)}px ${convertViewPointPx(24)}px;
`

const UserButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${convertViewPointPx(16)}px;
`

const UserInfoCard = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  right: 0;
  width: 35%;
  height: 100%;
  z-index: 3;
  background-color: #fff;

  box-shadow: ${join(
      map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )}
    rgba(0, 0, 0, 0.08);

  @media all and (min-width: 641px) {
    top: ${convertViewPointPx(90)}px;
  }
  @media all and (max-width: 640px) {
    top: ${convertViewPointPx(72)}px;
  }
`

const GridContainer = styled.div`
  display: grid;
  align-items: center;
  background: #ffffff;
  padding: ${convertViewPointPx(32)}px;

  @media all and (min-width: 641px) {
    grid-template-columns: repeat(1, 100%);
    gap: 0px ${convertViewPointPx(80)}px;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: repeat(1, 100%);
    gap: 0px ${convertViewPointPx(24)}px;
  }

  .buttonWrap {
    text-align: right;
  }
`

const GridItemWrap = styled.div`
  display: grid;
  align-items: center;
  height: ${convertViewPointPx(60)}px;

  @media all and (min-width: 641px) {
    grid-template-columns: ${convertViewPointPx(180)}px minMax(${convertViewPointPx(200)}px, auto);
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 50% 50%;
  }

  .title {
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    @media all and (min-width: 641px) {
      font-size: ${convertViewPointPx(14)}px;
    }
    @media all and (max-width: 640px) {
      font-size: ${convertViewPointPx(12)}px;
    }
  }
  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: ${Colors.black};
    @media all and (min-width: 641px) {
      font-size: ${convertViewPointPx(14)}px;
    }
    @media all and (max-width: 640px) {
      font-size: ${convertViewPointPx(12)}px;
    }
  }
`

function AdminUser(props) {
  const {header, headerDetail, headerCreate} = User

  const [data, setData] = useState({
    header,
    data: [],
  })

  const [rawData, setRawData] = useState([])
  const [programList, setProgramList] = useState([])
  const [roleList, setRoleList] = useState([])
  const [selectRole, setSelectRole] = useState(null)

  const [selectUser, setSelectUser] = useState(null)
  const [isCreate, setIsCreate] = useState(false)
  const [isModify, setIsModify] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const getRoleList = () => {
    try {
      Api.getInstance()
        .get(Api.url + '/user/role')
        .then((res) => {
          if (res.data.result) {
            const list = res.data.data
            const options = list.map((item) => ({
              ...item,
              name: item.name,
              value: item.id,
            }))
            setRoleList(options)
          }
        })
    } catch (e) {}
  }

  const getUserList = () => {
    try {
      Api.getInstance()
        .get(Api.url + '/user')
        .then((res) => {
          if (res.data.result) {
            const list = res.data.data.map((user) => ({
              id: user.id,
              name: user.name,
              roleName: user.roleName,
              phoneNum: user.phoneNum,
              num: user.num,
              mail: user.mail,
              status: user.status === true ? '사용' : '미사용',
              pvms: user.pvms === true ? '등록' : '미등록',
              drms: user.drms === true ? '등록' : '미등록',
              derms: user.derms === true ? '등록' : '미등록',
              lastLoginTimestamp: user.lastLoginTimestamp,
            }))

            setData({
              ...data,
              data: list,
            })

            setRawData(res.data.data)
          }
        })
    } catch (e) {}
  }

  const getProgram = async () => {
    const allProgramRes = await Api.getInstance().get(Api.url + '/program')
    const allProgramList = allProgramRes.data.data
    setProgramList(allProgramList)
  }

  const getProgramBody = () => {
    if (!selectUser) return []

    const body = []
    const pvms = programList.find((p) => p.name === 'PVMS')
    const drms = programList.find((p) => p.name === 'DRMS')
    const derms = programList.find((p) => p.name === 'DERMS')

    if (selectUser.pvms === true && pvms) {
      body.push(pvms.id)
    }

    if (selectUser.drms === true && drms) {
      body.push(drms.id)
    }

    if (selectUser.derms === true && derms) {
      body.push(derms.id)
    }

    return body
  }

  const resetUserPass = () => {
    if (confirm('이 사용자의 비밀번호를 초기화하시겠습니까?')) {
      Api.getInstance()
        .post(Api.url + '/user/' + selectUser.id + '/reset')
        .then((res) => {
          if (res.data.result) {
            alert('사용자의 비밀번호가 0000으로 초기화되었습니다.')
          } else {
            alert('사용자의 비밀번호가 초기화되지 않았습니다.\n관리자에게 문의해주세요.')
          }
        })
        .catch(() => {
          alert('사용자의 비밀번호가 초기화되지 않았습니다.')
        })
    }
  }

  const handleCreateUser = () => {
    setSelectUser({
      id: null,
      pass: null,
      passConfirm: null,
      name: null,
      num: null,
      phoneNum: null,
      mail: null,
      status: true,
      pvms: false,
      drms: false,
      derms: false,
      roleId: null,
    })
    setIsOpen(true)
    setIsCreate(true)
  }

  const createUser = async () => {
    if (confirm('생성하시겠습니까?')) {
      if (!selectUser.id || selectUser.id.trim() === '') {
        alert('아이디를 입력해주세요.')
        return false
      }

      if (selectUser.id.indexOf(' ') > -1) {
        alert('아이디에는 공백을 포함할 수 없습니다.')
        return false
      }

      if (!selectUser.pass || selectUser.pass.trim() === '') {
        alert('비밀번호를 입력해주세요.')
        return false
      }

      if (selectUser.pass.indexOf(' ') > -1) {
        alert('비밀번호에는 공백을 포함할 수 없습니다.')
        return false
      }

      if (!selectUser.passConfirm || selectUser.passConfirm.trim() === '') {
        alert('비밀번호 확인란을 입력해주세요.')
        return false
      }

      if (selectUser.pass !== selectUser.passConfirm) {
        alert('비밀번호를 다르게 입력하셨습니다. 확인해주세요.')
        return false
      }

      if (!selectUser.name || selectUser.name.trim() === '') {
        alert('이름을 입력해주세요.')
        return false
      }

      if (!selectUser.phoneNum || selectUser.phoneNum.trim() === '') {
        alert('휴대폰번호를 입력해주세요.')
        return false
      }

      // if (!selectUser.num || selectUser.num.trim() === '') {
      //   alert('전화번호를 입력해주세요.')
      //   return false
      // }

      // if (!selectUser.mail || selectUser.mail.trim() === '') {
      //   alert('이메일을 입력해주세요.')
      //   return false
      // }

      // if (selectUser.mail.indexOf('@') < 0) {
      //   alert('이메일형식을 확인해주세요.')
      //   return false
      // }

      if (!selectRole) {
        alert('사용자 유형을 선택해주세요.')
        return false
      }

      try {
        const userCreateRes = await Api.getInstance().post(Api.url + '/user', {
          id: selectUser.id.replace(/ /g, ''),
          pass: md5(selectUser.pass.replace(/ /g, '')),
          name: selectUser.name.trim(),
          num: convertCallNum(selectUser.num),
          phoneNum: convertCallNum(selectUser.phoneNum),
          mail: selectUser.mail ? selectUser.mail.replace(/ /g, '') : null,
          status: selectUser.status,
          program: getProgramBody(),
          roleId: selectRole.id,
        })

        if (userCreateRes.data.result) {
          alert('생성되었습니다.')
          getUserList()
          setIsOpen(false)
        } else {
          alert('사용자 정보가 생성되지 않았습니다.\n관리자에게 문의해주세요.')
          return false
        }
      } catch (e) {
        if (e.response.data.data?.errorCode === 'UE01') {
          alert('이미 등록된 아이디입니다. \n다른 아이디를 사용해주세요.')
          return false
        }

        alert('사용자 정보가 생성되지 않았습니다.\n관리자에게 문의해주세요.')
        return false
      }
      return true
    }
    return false
  }

  const removeUser = () => {
    if (confirm('삭제하시겠습니까?')) {
      Api.getInstance()
        .delete(Api.url + '/user/' + selectUser.id)
        .then((res) => {
          if (res.data.result) {
            alert('사용자가 삭제되었습니다.')
            getUserList()
            setIsOpen(false)
          } else {
            alert('사용자 정보가 삭제되지 않았습니다.\n관리자에게 문의해주세요.')
          }
        })
        .catch(() => {
          alert('사용자 정보가 삭제되지 않았습니다.\n관리자에게 문의해주세요.')
        })
    }
  }

  const modifyUser = async () => {
    if (confirm('수정하시겠습니까?')) {
      let isChangePassword = false

      if (selectUser.pass && selectUser.pass.trim() !== '') {
        if (selectUser.pass.indexOf(' ') > -1) {
          alert('비밀번호에는 공백을 포함할 수 없습니다.')
          return false
        }

        if (!selectUser.passConfirm || selectUser.passConfirm.trim() === '') {
          alert('비밀번호 확인란을 입력해주세요.')
          return false
        }

        if (selectUser.pass !== selectUser.passConfirm) {
          alert('비밀번호를 다르게 입력하셨습니다. 확인해주세요.')
          return false
        }

        isChangePassword = true
      }

      if (!selectUser.name || selectUser.name.trim() === '') {
        alert('이름을 입력해주세요.')
        return false
      }

      if (!selectUser.phoneNum || selectUser.phoneNum.trim() === '') {
        alert('휴대폰번호를 입력해주세요.')
        return false
      }

      // if (!selectUser.num || selectUser.num.trim() === '') {
      //   alert('전화번호를 입력해주세요.')
      //   return false
      // }

      if (!selectUser.mail || selectUser.mail.trim() === '') {
        alert('이메일을 입력해주세요.')
        return false
      }

      if (selectUser.mail.indexOf('@') < 0) {
        alert('이메일형식을 확인해주세요.')
        return false
      }

      if (!selectRole) {
        alert('사용자 유형을 선택해주세요.')
        return false
      }

      try {
        const body = {
          name: selectUser.name.trim(),
          num: convertCallNum(selectUser.num),
          phoneNum: convertCallNum(selectUser.phoneNum),
          mail: selectUser.mail.replace(/ /g, ''),
          status: selectUser.status,
          program: getProgramBody(),
          roleId: selectRole.id,
        }

        if (isChangePassword) {
          body.pass = md5(selectUser.pass.replace(/ /g, ''))
        }

        const userChangeRes = await Api.getInstance().put(Api.url + '/user/' + selectUser.id, body)

        if (userChangeRes.data.result) {
          alert('변경되었습니다.')
          getUserList()
          setIsOpen(false)
        } else {
          alert('사용자 정보가 변경되지 않았습니다.\n관리자에게 문의해주세요.')
          return false
        }
      } catch (e) {
        console.log(e)
        alert('사용자 정보가 변경되지 않았습니다.\n관리자에게 문의해주세요.')
        return false
      }
      return true
    }
    return false
  }

  const handleCancelModify = () => {
    if (selectUser.id) {
      setSelectUser(rawData.find((user) => user.id === selectUser.id))
    } else {
      setSelectUser(null)
    }
    setIsModify(false)
  }

  const handleCancelCreate = () => {
    setSelectUser(null)
    setIsCreate(false)
    setIsOpen(false)
  }

  const handleClickRow = (id) => {
    const user = rawData.find((user) => user.id === id)
    const userRole = roleList ? roleList.find((role) => role.id === user.roleId) : null

    setSelectUser(user)
    setSelectRole(userRole)
    setIsOpen((state) => !state)
  }

  const renderDetailInfo = useMemo(() => {
    return map([...headerDetail], (item) => {
      if (
        !item.isElement &&
        selectUser &&
        !(isModify === false && item.filterType === 'password')
      ) {
        return (
          <GridItemWrap key={item.key}>
            <span className="title">{item.title}</span>
            {isModify && item.isEditable === true
              ? (() => {
                  if (item.filterType === 'password') {
                    return (
                      <Input
                        line
                        type={'password'}
                        height={32}
                        fontSize={12}
                        isPassword={false}
                        autoComplete="on"
                        value={selectUser[item.key] || ''}
                        onChange={(e) => setSelectUser({...selectUser, [item.key]: e.target.value})}
                      />
                    )
                  } else if (item.filterType === 'select') {
                    return (
                      <Dropbox
                        height={40}
                        fontSize={14}
                        data={roleList}
                        selected={selectRole}
                        onChange={(item) => setSelectRole(item)}
                        placeholder="선택해주세요."
                      />
                    )
                  } else if (item.filterType === 'check') {
                    return (
                      <Checkbox
                        id={item.key}
                        checked={selectUser[item.key] || false}
                        text="등록"
                        onChange={(e) =>
                          setSelectUser({...selectUser, [item.key]: e.target.checked})
                        }
                      />
                    )
                  } else {
                    return (
                      <Input
                        line
                        height={36.5}
                        fontSize={14}
                        value={selectUser[item.key] || ''}
                        onChange={(e) => setSelectUser({...selectUser, [item.key]: e.target.value})}
                      />
                    )
                  }
                })()
              : (() => {
                  if (item.filterType === 'check') {
                    if (item.key === 'status') {
                      return (
                        <span className="value">
                          {selectUser[item.key] === true ? '사용' : '미사용'}
                        </span>
                      )
                    }
                    return (
                      <span className="value">
                        {selectUser[item.key] === true ? '등록' : '미등록'}
                      </span>
                    )
                  } else if (item.filterType === 'select') {
                    if (item.key === 'roleId') {
                      const userRole = roleList
                        ? roleList.find((role) => role.id === selectUser[item.key])
                        : null
                      return <span className="value">{userRole ? userRole.name : '-'}</span>
                    }
                  } else {
                    return <span className="value">{selectUser[item.key] || '-'}</span>
                  }
                })()}
          </GridItemWrap>
        )
      }
    })
  }, [headerDetail, selectUser, isModify])

  const renderCreateInfo = useMemo(() => {
    return map([...headerCreate], (item) => {
      if (!item.isElement && selectUser) {
        return (
          <GridItemWrap key={item.key}>
            <span className="title">{item.title}</span>
            {(() => {
              if (item.filterType === 'password') {
                return (
                  <Input
                    line
                    type={'password'}
                    height={32}
                    fontSize={12}
                    isPassword={false}
                    autoComplete="on"
                    value={selectUser[item.key] || ''}
                    onChange={(e) => setSelectUser({...selectUser, [item.key]: e.target.value})}
                  />
                )
              } else if (item.filterType === 'select') {
                return (
                  <Dropbox
                    height={40}
                    fontSize={14}
                    data={roleList}
                    selected={selectRole}
                    onChange={(item) => setSelectRole(item)}
                    placeholder="선택해주세요."
                  />
                )
              } else if (item.filterType === 'check') {
                return (
                  <Checkbox
                    id={item.key}
                    checked={selectUser[item.key] || false}
                    text="등록"
                    onChange={(e) => setSelectUser({...selectUser, [item.key]: e.target.checked})}
                  />
                )
              } else {
                return (
                  <Input
                    line
                    height={36.5}
                    fontSize={14}
                    value={selectUser[item.key] || ''}
                    onChange={(e) => setSelectUser({...selectUser, [item.key]: e.target.value})}
                  />
                )
              }
            })()}
          </GridItemWrap>
        )
      }
    })
  }, [headerCreate, selectUser, isCreate])

  useEffect(() => {
    getUserList()
    getProgram()
    getRoleList()
  }, [])

  return (
    <Container>
      <UserButtonWrap>
        <Button primary width={120} height={40} fontSize={14} onClick={handleCreateUser}>
          사용자 생성
        </Button>
      </UserButtonWrap>
      <Table
        clickRow={handleClickRow}
        headers={data.header}
        data={data.data}
        maxRow={50}
        hasPagination={true}
        primaryKey="id"></Table>
      <UserInfoCard isOpen={isOpen}>
        <GridContainer>
          <div className="buttonWrap">
            {(isModify || isCreate) && (
              <Button
                secondary
                width={55}
                height={32}
                fontSize={12}
                margin={[0, 8, 0, 0]}
                onClick={isCreate ? handleCancelCreate : handleCancelModify}>
                취소
              </Button>
            )}
            {!isCreate && (
              <Button
                secondary
                width={150}
                height={32}
                fontSize={12}
                margin={[0, 8, 0, 0]}
                onClick={resetUserPass}>
                비밀번호 초기화
              </Button>
            )}
            {!isCreate && (
              <Button
                secondary
                width={55}
                height={32}
                fontSize={12}
                margin={[0, 8, 0, 0]}
                onClick={removeUser}>
                삭제
              </Button>
            )}
            {!isCreate && (
              <Button
                primary
                width={55}
                height={32}
                fontSize={12}
                onClick={(e) => {
                  if (isModify) {
                    modifyUser().then((result) => {
                      if (result) {
                        setIsModify(!isModify)
                      }
                    })
                  } else {
                    setIsModify(!isModify)
                  }
                }}>
                {isModify ? '완료' : '수정'}
              </Button>
            )}
            {isCreate && (
              <Button
                primary
                width={55}
                height={32}
                fontSize={12}
                onClick={(e) => {
                  createUser().then((result) => {
                    if (result) {
                      setIsCreate(false)
                    }
                  })
                }}>
                완료
              </Button>
            )}
          </div>
          {isCreate ? renderCreateInfo : renderDetailInfo}
        </GridContainer>
      </UserInfoCard>
    </Container>
  )
}

export default AdminUser
