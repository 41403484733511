import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import {Button, Consulting} from '../components'
import {convertViewPointPx, getIsViewType} from '../const/common'
import {PageContent} from '../structure'

// import {useNavigate} from 'react-router-dom'

const Container = styled.div`
  background: #ffffff;
`
const Banner = styled(Styles.Banner)``
const Title = styled(Styles.BannerTitle)``
const SubTitle = styled(Styles.BannerSubTitle)``
const Content = styled(Styles.PageContent)``
const ContentTitle = styled(Styles.PageContentTitle)``

function ScadaView() {
  // const navigate = useNavigate()
  const {scada} = PageContent
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(getIsViewType() === 'mobile')
  }, [])

  const renderContents = useMemo(() => {
    const contents = scada.content[isMobile ? 'mobile' : 'web']

    return (
      <>
        <div className="contentWrap">
          <div className="content">
            <ContentTitle>
              원격감시/제어 기능을 갖춘 {isMobile && <br />} 1~3MW 태양광 발전소의 경우,
            </ContentTitle>
            <ContentTitle color={Colors.primary}>전기안전관리 업무 대행 가능</ContentTitle>
            <img
              src={contents[0]}
              style={{width: `${convertViewPointPx(isMobile ? 235 : 838)}px`}}
            />
          </div>
        </div>
        <div className="contentWrap" style={{background: Colors.light_blue}}>
          <div className="content">
            <ContentTitle
              style={{
                fontSize: isMobile ? `${convertViewPointPx(18)}px` : `${convertViewPointPx(48)}px`,
              }}>
              인업스 원격감시제어사업의 효과를 경험하세요
            </ContentTitle>
            <img src={contents[1]} />
          </div>
        </div>
        <div className="contentWrap">
          <div className="content">
            <ContentTitle>사업 구성도</ContentTitle>
            <img src={contents[2]} />
            <img src={contents[3]} />
          </div>
        </div>
      </>
    )
  }, [scada, isMobile])

  return (
    <Container>
      <Banner src={isMobile ? scada.banner.mobile : scada.banner.web}>
        <div className="wrapper">
          <Title>{scada.title}</Title>
          <SubTitle>{scada.subTitle}</SubTitle>
          <Button
            primary
            width={isMobile ? 200 : 320}
            height={isMobile ? 49 : 69}
            margin={[isMobile ? 20 : 56, 0, 0, 0]}
            onClick={() => {
              window.location.href = 'mailto:support@inups.kr'
            }}
          >
            서비스 문의하기
          </Button>
        </div>
      </Banner>
      <Content>{renderContents}</Content>
      <Consulting />
    </Container>
  )
}

export default ScadaView
