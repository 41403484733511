// drms_web
import BannerWebDrms from '../assets/image/web/drms/banner.png'
import Content01WebDrms from '../assets/image/web/drms/content01.png'
import Content02WebDrms from '../assets/image/web/drms/content02.png'
import Content03WebDrms from '../assets/image/web/drms/content03.png'
import Content04WebDrms from '../assets/image/web/drms/content04.png'
// drms_mo
import BannerMoDrms from '../assets/image/mobile/drms/banner.png'
import Content01MoDrms from '../assets/image/mobile/drms/content01.png'
import Content02MoDrms from '../assets/image/mobile/drms/content02.png'
import Content03MoDrms from '../assets/image/mobile/drms/content03.png'
import Content04MoDrms from '../assets/image/mobile/drms/content04.png'

// vpp_web
import BannerWebVpp from '../assets/image/web/vpp/banner.png'
import Content01WebVpp from '../assets/image/web/vpp/content01.png'
import Content02WebVpp from '../assets/image/web/vpp/content02.png'
import Content03WebVpp from '../assets/image/web/vpp/content03.png'
import Content04WebVpp from '../assets/image/web/vpp/content04.png'
import Content05WebVpp from '../assets/image/web/vpp/content05.png'
// vpp_mo
import BannerMoVpp from '../assets/image/mobile/vpp/banner.png'
import Content01MoVpp from '../assets/image/mobile/vpp/content01.png'
import Content02MoVpp from '../assets/image/mobile/vpp/content02.png'
import Content03MoVpp from '../assets/image/mobile/vpp/content03.png'
import Content04MoVpp from '../assets/image/mobile/vpp/content04.png'
import Content05MoVpp from '../assets/image/mobile/vpp/content05.png'

// scada_web
import BannerWebScada from '../assets/image/web/scada/banner.png'
import Content01WebScada from '../assets/image/web/scada/content01.png'
import Content02WebScada from '../assets/image/web/scada/content02.png'
import Content03WebScada from '../assets/image/web/scada/content03.png'
// scada_mo
import BannerMoScada from '../assets/image/mobile/scada/banner.png'
import Content01MoScada from '../assets/image/mobile/scada/content01.png'
import Content02MoScada from '../assets/image/mobile/scada/content02.png'
import Content03MoScada from '../assets/image/mobile/scada/content03.png'

// pvms_web
import BannerWebPvms from '../assets/image/web/pvms/banner.png'
import Content01WebPvms from '../assets/image/web/pvms/content01.png'
import Content02WebPvms from '../assets/image/web/pvms/content02.png'
import Content03WebPvms from '../assets/image/web/pvms/content03.png'
// pvms_mo
import BannerMoPvms from '../assets/image/mobile/pvms/banner.png'
import Content01MoPvms from '../assets/image/mobile/pvms/content01.png'
import Content02MoPvms from '../assets/image/mobile/pvms/content02.png'
import Content03MoPvms from '../assets/image/mobile/pvms/content03.png'

const pageContent = {
  drms: {
    title: '수요관리사업',
    subTitle: '아낀 전기를 전력시장에 판매하여 고정 수익을 발생시킵니다',
    banner: {web: BannerWebDrms, mobile: BannerMoDrms},
    content: {
      web: [Content01WebDrms, Content02WebDrms, Content03WebDrms, Content04WebDrms],
      mobile: [Content01MoDrms, Content02MoDrms, Content03MoDrms, Content04MoDrms],
    },
  },
  vpp: {
    title: '전력중개사업',
    subTitle: '발전량 예측 정확도에 따라 추가 수익이 발생합니다',
    banner: {web: BannerWebVpp, mobile: BannerMoVpp},
    content: {
      tab0: {
        web: [Content01WebVpp, Content02WebVpp, Content03WebVpp, Content04WebVpp],
        mobile: [Content01MoVpp, Content02MoVpp, Content03MoVpp, Content04MoVpp],
      },
      tab1: null,
      tab2: {web: [Content05WebVpp], mobile: [Content05MoVpp]},
    },
  },
  scada: {
    title: '원격감시제어사업',
    subTitle: '감시, 제어, 경보, 통신, 보안의 기능을 통합한 태양광 발전소 종합 관리 시스템입니다',
    banner: {web: BannerWebScada, mobile: BannerMoScada},
    content: {
      web: [Content01WebScada, Content02WebScada, Content03WebScada],
      mobile: [Content01MoScada, Content02MoScada, Content03MoScada],
    },
  },
  pvms: {
    title: '모니터링 서비스(PVMS)',
    subTitle:
      '약 800개 이상의 PV 사이트를 대상으로 자체 시스템을 통한 PV 모니터링 서비스를 운영합니다',
    banner: {web: BannerWebPvms, mobile: BannerMoPvms},
    content: {
      web: [Content01WebPvms, Content02WebPvms, Content03WebPvms],
      mobile: [Content01MoPvms, Content02MoPvms, Content03MoPvms],
    },
  },
}
export default pageContent
