import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import KnLogo from '../assets/image/logo/kn.svg'
import KdLogo from '../assets/image/logo/kd.svg'
import KsLogo from '../assets/image/logo/ks.svg'
import DlLogo from '../assets/image/logo/dl.svg'
import DeLogo from '../assets/image/logo/de.svg'
import MsLogo from '../assets/image/logo/ms.svg'
import CnLogo from '../assets/image/logo/cn.svg'
import YcLogo from '../assets/image/logo/yc.svg'
import JbLogo from '../assets/image/logo/jb.svg'
import SeaLogo from '../assets/image/logo/sea.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SliderWrap = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 25px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100vw;
  min-width: 375px;
  height: 53px;

  *:focus {
    outline: none;
  }
  .slick-list {
    height: 100%;
  }
  .slick-slider {
    z-index: 500;
    width: 100%;
    height: 100%;
  }
  .slick-track {
    overflow-y: hidden;
    height: 100%;
    & > div > div {
      width: 125px;
      height: 100%;
      display: flex;
    }
    .slick-current {
    }
  }
  .slick-slide {
    box-sizing: border-box;
  }
`

const Contents = styled.div`
  width: 125px;
  height: auto;
  box-sizing: border-box;
  opacity: 0.6;
  padding-left: 12px;
  padding-right: 12px;
`

const logos = [
  {
    id: 'kn',
    img: KnLogo,
  },
  {
    id: 'kn',
    img: KdLogo,
  },
  {
    id: 'ks',
    img: KsLogo,
  },
  {
    id: 'dl',
    img: DlLogo,
  },
  {
    id: 'de',
    img: DeLogo,
  },
  {
    id: 'ms',
    img: MsLogo,
  },
  {
    id: 'cn',
    img: CnLogo,
  },
  {
    id: 'yc',
    img: YcLogo,
  },
  {
    id: 'Jb',
    img: JbLogo,
  },
  {
    id: 'sea',
    img: SeaLogo,
  },
]

function GasAllianceSlider() {
  const settings = {
    dots: false,
    draggable: false,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 500,
    variableWidth: true,
    centerMode: false,
  }

  return (
    <SliderWrap>
      <Slider {...settings}>
        {logos.map((logo, i) => (
          <Contents key={i}>
            <img src={logo.img} width="100%" height="100%" />
          </Contents>
        ))}
      </Slider>
    </SliderWrap>
  )
}

export default GasAllianceSlider
