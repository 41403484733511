import React, {useEffect, useState, useMemo} from 'react'
import Main from './Main'
import {Session} from './const'
import {HelpUserView, Login, Logout} from './pages'
import {Routes, Route} from 'react-router-dom'
import {Menu} from './structure'
import {getRountingMenus} from './const/common'
import {join, map} from 'lodash-es'

function App() {
  const [routes, setRoutes] = useState([])
  const [width, setWidth] = React.useState(0)
  const refTimer = React.useRef(null)

  React.useLayoutEffect(() => {
    function updateSize() {
      const clientWidth = document.documentElement.clientWidth
      if (refTimer.current !== null) {
        // 계속 입력중임으로 타이머리셋하고 새로 등록
        window.clearTimeout(refTimer.current)
        refTimer.current = null
      }

      refTimer.current = window.setTimeout(() => {
        if (clientWidth > 640) {
          // web
          if (clientWidth > 1200) {
            setWidth(clientWidth)
          } else {
            setWidth(1200)
          }
        } else {
          // mobile
          setWidth(clientWidth)
        }
      }, 200)
    }
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    setRoutes(getRountingMenus(Menu || {}, 'path'))
  }, [])

  useEffect(() => {
    if (width && +Session.get('width') !== +width) {
      location.reload()
      Session.set('width', width)
    }
  }, [width])

  const renderRoutes = useMemo(() => {
    return (
      <Routes>
        <Route path="/" element={<Main />}>
          {map(routes, (item, index) => {
            const path = item.path
            const params = item.params || []
            const paramsUrl = join(
              map(params, (param) => `/:${param}`),
              '',
            )
            return <Route path={path + paramsUrl} element={item.el} key={index} />
          })}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/help" element={<HelpUserView />} />
      </Routes>
    )
  }, [routes])
  return renderRoutes
}

export default App
