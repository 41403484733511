import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import Colors from '../assets/theme/colors'
import {Button} from '../components'
import {convertViewPointPx, getIsViewType} from '../const/common'

// import {useNavigate} from 'react-router-dom'

const ConsultingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background || Colors.light_blue};

  @media all and (min-width: 641px) {
    padding: ${convertViewPointPx(64)}px 0;
    & p {
      margin: 0;
      font-weight: 700;
      font-size: ${convertViewPointPx(40)}px;
      line-height: ${convertViewPointPx(48)}px;
    }
  }

  @media all and (max-width: 640px) {
    padding: ${convertViewPointPx(50)}px 0;
    & p {
      margin: 0;
      font-weight: 700;
      font-size: ${convertViewPointPx(20)}px;
      line-height: ${convertViewPointPx(32)}px;
    }
  }

  ${(props) => {
    return css`
      & button {
        background: ${props.buttonColor} !important;
      }
    `
  }}
`

function Consulting(props) {
  // const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(getIsViewType() === 'mobile')
  }, [])

  return (
    <ConsultingContainer {...props}>
      <p>인업스의 전문가와 상담하세요</p>
      <Button
        primary
        margin={[isMobile ? 24 : 48, 0, 0, 0]}
        width={isMobile ? 180 : 363}
        radius={48}
        onClick={() => {
          window.location.href = 'mailto:support@inups.kr'
        }}
      >
        서비스 문의하기
      </Button>
    </ConsultingContainer>
  )
}

export default Consulting
