import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {convertViewPointPx} from '../const/common'
import Colors from '../assets/theme/colors'
import {join, map} from 'lodash-es'
import Styles from '../assets/theme/styles'

import EyeOff from '../assets/image/icon/eyeOff.svg'
import EyeOn from '../assets/image/icon/eye.svg'

const InputContainer = styled(Styles.Container)`
  position: relative;
  padding: 0;
  width: ${(props) => (props.width ? convertViewPointPx(props.width) + 'px' : '100%')};
  height: ${(props) => convertViewPointPx(props.height || 52) + 'px'};
  border-radius: ${convertViewPointPx(10)}px;
  margin: ${(props) => {
    if (props.margin) {
      const marginList = map(props.margin, (i) => convertViewPointPx(i ?? 0) + 'px')
      return join(marginList, ' ')
    } else {
      return 0
    }
  }};

  & input {
    border: none;
    width: 100%;
    height: 100%;
    background: ${Colors.white_60};
    font-weight: 400;

    &::placeholder {
      color: ${Colors.black_40};
    }

    font-size: ${(props) => convertViewPointPx(props.fontSize || 16)}px;
    line-height: ${convertViewPointPx(19)}px;
    border-radius: ${convertViewPointPx(10)}px;
    padding: ${(props) => {
      const marginList = props.isPassword ? [0, 52, 0, 14] : [0, 14, 0, 14]
      return join(
        map(marginList, (i) => convertViewPointPx(i) + 'px'),
        ' ',
      )
    }};

    &:focus {
      border: 1px solid ${Colors.primary};
      outline: none !important;
      background: #ffffff;
    }
    @media all and (max-width: 640px) {
      background: rgba(247, 247, 255, 1);
    }
  }

  ${(props) =>
    props.line &&
    css`
      border: 1px solid rgba(26, 26, 26, 0.2);
      input {
        &:focus {
          border: none;
        }
      }
    `}
`

const ShowPwBtn = styled.button`
  position: absolute;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  background-color: transparent;
  background-image: url(${(props) => (props.isShow ? EyeOn : EyeOff)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  transform: translateY(-50%);

  right: ${convertViewPointPx(14)}px;
  top: 50%;
  width: ${convertViewPointPx(24)}px;
  height: ${convertViewPointPx(24)}px;
`

/**
 *
 * @param {width, height, margin([mt,mr,mb,ml])} props
 * @returns
 */
function Input(props) {
  const {isPassword, setIsShowPassword, isShowPassword, margin, line, ...rest} = props
  return (
    <InputContainer isPassword={isPassword} margin={margin} line={line} {...rest}>
      <input {...rest}></input>
      {isPassword && (
        <ShowPwBtn isShow={isShowPassword} onClick={setIsShowPassword} type="button"></ShowPwBtn>
      )}
    </InputContainer>
  )
}

Input.propTypes = {
  isPassword: PropTypes.bool,
  isShowPassword: PropTypes.bool,
  setIsShowPassword: PropTypes.func,
  margin: PropTypes.array,
  line: PropTypes.bool,
}

export default Input
