/*
  Login Error Code
  - AE01: 프로그램이 매칭되지 않은 사용자
  - AE02: 존재하지 않는 사용자
  - AE03: 중복로그인 만료
  - AE04: 인증시간 만료
  - AE05: 알수없는 에러
*/
export const LoginErrorMessage = {
  AE01: '로그인 가능한 시스템이 없습니다.\n관리자에게 문의해주세요.',
  AE02: '아이디 또는 비밀번호를 확인해주세요.',
  AE03: '다른 곳에서 로그인되어 로그아웃 처리 되었습니다.',
  AE04: '로그인 유효 시간이 만료되었습니다. 다시 로그인해주세요.',
  AE05: '알수 없는 장애가 발생했습니다.\n관리자에게 문의해주세요.',
}
