import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import Colors from '../assets/theme/colors'
import {convertViewPointPx} from '../const/common'

import CheckboxOff from '../assets/image/icon/checkboxOff.png'
import CheckboxOn from '../assets/image/icon/checkboxOn.png'

const CheckboxContainer = styled(Styles.Container)`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  & label {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }

  & input[type='checkbox'] {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
  }

  & input[type='checkbox']:checked ~ label img {
    content: url(${CheckboxOn});
  }

  & img {
    content: url(${CheckboxOff});
    width: ${convertViewPointPx(24)}px;
    height: ${convertViewPointPx(24)}px;
    margin-right: ${convertViewPointPx(8)}px;
  }
`

const CheckboxName = styled.span`
  font-weight: 400;
  color: ${Colors.black_40};

  font-size: ${convertViewPointPx(14)}px;
  line-height: ${convertViewPointPx(17)}px;
`

function Checkbox(props) {
  const {id, text, ...rest} = props

  return (
    <CheckboxContainer>
      <input type="checkbox" id={id} {...rest} />
      <label htmlFor={id}>
        <img />
        {text ? <CheckboxName>{text}</CheckboxName> : ''}
      </label>
    </CheckboxContainer>
  )
}

Checkbox.propTypes = {
  id: PropTypes.any,
  text: PropTypes.string,
}

export default Checkbox
