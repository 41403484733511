const UserInfo = {
  header: [
    {key: 'id', title: '아이디', align: 'center'},
    {key: 'name', title: '이름', align: 'center', isEditable: true},
    {
      key: 'oldPass',
      title: '현재 비밀번호',
      align: 'center',
      isEditable: true,
      isShowPassword: false,
      inputType: 'password',
    },
    {
      key: 'newPass',
      title: '변경 비밀번호',
      align: 'center',
      isEditable: true,
      isShowPassword: false,
      inputType: 'password',
    },
    {key: 'phoneNum', title: '휴대폰번호', align: 'center', isEditable: true},
    {key: 'num', title: '전화번호', align: 'center', isEditable: true},
    {key: 'mail', title: '이메일', align: 'center', isEditable: true},
    {key: 'lastLoginTimestamp', title: '마지막 로그인 시간', align: 'center'},
  ],
}
export default UserInfo
