import React from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import InupsLogo from '../assets/image/logo/inups.png'
import {convertViewPointPx} from '../const/common'

const Contents = styled(Styles.Contents)`
  display: flex;
  justify-content: center;
`

const Container = styled(Styles.Container)`
  @media all and (min-width: 641px) {
    padding: ${convertViewPointPx(68)}px 0;
    width: 100%;
    // max-width: 1180px;
    max-width: 61.5%;
    display: flex;
    flex-direction: column;
  }

  @media all and (max-width: 640px) {
    padding: ${convertViewPointPx(68)}px ${convertViewPointPx(26)}px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Paper = styled(Styles.Paper)``

const Text = styled.div`
  font-family: 'Pretendard-Regular';
  color: rgba(26, 26, 26, 0.5);
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};

  & > * {
    font-family: 'Pretendard-Regular';
  }

  & > span {
    &:first-child {
      padding-right: ${convertViewPointPx(10)}px;
    }
  }

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(16)}px;
    margin-bottom: ${convertViewPointPx(12)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(12)}px;
    margin-bottom: ${convertViewPointPx(10)}px;
  }
`

const Logo = styled.div`
  @media all and (min-width: 641px) {
    width: ${convertViewPointPx(83)}px;
    height: auto;
    padding-bottom: ${convertViewPointPx(30)}px;
  }

  @media all and (max-width: 640px) {
    width: ${convertViewPointPx(55)}px;
    height: auto;
    padding-bottom: ${convertViewPointPx(24)}px;
  }
`

function Footer(props) {
  return (
    <Contents>
      <Container>
        <Paper>
          <Logo>
            <img src={InupsLogo} width="100%" height="100%" />
          </Logo>
          <Text cursor="auto">
            <span>(주)인업스</span>
            <span>대표이사: 강인철</span>
          </Text>
          <Text cursor="auto">서울특별시 서초구 강남대로 311, 1108호</Text>
          <Text cursor="auto">Copyright © {new Date().getFullYear()} INUPS. All rights reserved.</Text>
        </Paper>
      </Container>
    </Contents>
  )
}

Footer.propTypes = {}

export default Footer
