import React from 'react'
import PropTypes from 'prop-types'
import {SvgIcon} from '@material-ui/core'

export default function Icons(props) {
  const {
    id,
    width,
    height,
    transform,
    vLeft,
    vTop,
    vWidth,
    vHeight,
    margin,
    color,
    hover,
    hoverColor,
    className,
  } = props

  const mapping = {
    menu: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z"
          fill={color || '#1A1A1A'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z"
          fill={color || '#1A1A1A'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 18C2 17.4477 2.44772 17 3 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18Z"
          fill={color || '#1A1A1A'}
        />
      </>
    ),
    close: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5858L6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L13.4142 12L18.7071 6.70711Z"
        fill={color || '#1A1A1A'}
      />
    ),
    arrowRight: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
        fill={color || 'rgb(175 175 175)'}
      />
    ),
    filter: (
      <>
        <g clipPath="url(#clip0_1409_1484)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33337 5.00004C3.33337 4.63185 3.63185 4.33337 4.00004 4.33337H14C14.3682 4.33337 14.6667 4.63185 14.6667 5.00004C14.6667 5.36823 14.3682 5.66671 14 5.66671H4.00004C3.63185 5.66671 3.33337 5.36823 3.33337 5.00004Z"
            fill={color || 'rgba(51,51,51,0.4)'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33337 9.00004C5.33337 8.63185 5.63185 8.33337 6.00004 8.33337H12C12.3682 8.33337 12.6667 8.63185 12.6667 9.00004C12.6667 9.36823 12.3682 9.66671 12 9.66671H6.00004C5.63185 9.66671 5.33337 9.36823 5.33337 9.00004Z"
            fill={color || 'rgba(51,51,51,0.4)'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33337 13C7.33337 12.6319 7.63185 12.3334 8.00004 12.3334H10C10.3682 12.3334 10.6667 12.6319 10.6667 13C10.6667 13.3682 10.3682 13.6667 10 13.6667H8.00004C7.63185 13.6667 7.33337 13.3682 7.33337 13Z"
            fill={color || 'rgba(51,51,51,0.4)'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1409_1484">
            <rect width="16" height="16" fill="white" transform="translate(1 1)" />
          </clipPath>
        </defs>
      </>
    ),
    sortUp: (
      <>
        <g clipPath="url(#clip0_1409_1538)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3.99998C2 3.63179 2.29848 3.33331 2.66667 3.33331H7.33333C7.70152 3.33331 8 3.63179 8 3.99998C8 4.36817 7.70152 4.66665 7.33333 4.66665H2.66667C2.29848 4.66665 2 4.36817 2 3.99998Z"
            fill={color || '#565656'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7.99998C2 7.63179 2.29848 7.33331 2.66667 7.33331H7.33333C7.70152 7.33331 8 7.63179 8 7.99998C8 8.36817 7.70152 8.66665 7.33333 8.66665H2.66667C2.29848 8.66665 2 8.36817 2 7.99998Z"
            fill={color || '#565656'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333H8.66667C9.03486 11.3333 9.33333 11.6318 9.33333 12C9.33333 12.3682 9.03486 12.6666 8.66667 12.6666H2.66667C2.29848 12.6666 2 12.3682 2 12Z"
            fill={color || '#565656'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7447 3.3839C11.6661 3.41643 11.5924 3.46466 11.5285 3.52858L9.52851 5.52857C9.26816 5.78892 9.26816 6.21103 9.52851 6.47138C9.78886 6.73173 10.211 6.73173 10.4713 6.47138L11.3333 5.60945V12C11.3333 12.3682 11.6317 12.6666 11.9999 12.6666C12.3681 12.6666 12.6666 12.3682 12.6666 12V5.60946L13.5285 6.47138C13.7889 6.73173 14.211 6.73173 14.4713 6.47138C14.7317 6.21103 14.7317 5.78892 14.4713 5.52857L12.4716 3.52884C12.471 3.52828 12.4705 3.52772 12.4699 3.52716C12.4704 3.52763 12.4709 3.5281 12.4713 3.52858M12.0019 3.33332C12.0013 3.33331 12.0006 3.33331 11.9999 3.33331C11.9993 3.33331 11.9986 3.33331 11.9979 3.33332C11.9083 3.33358 11.8228 3.35154 11.7447 3.3839C11.8256 3.35043 11.9117 3.33357 11.9979 3.33332C11.9993 3.33331 12.0006 3.33331 12.0019 3.33332Z"
            fill={color || '#565656'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1409_1538">
            <rect width="16" height="16" fill={color || '#565656'} />
          </clipPath>
        </defs>
      </>
    ),
    sortDown: (
      <>
        <g clipPath="url(#clip0_1409_1242)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3.99998C2 3.63179 2.29848 3.33331 2.66667 3.33331H8.66667C9.03486 3.33331 9.33333 3.63179 9.33333 3.99998C9.33333 4.36817 9.03486 4.66665 8.66667 4.66665H2.66667C2.29848 4.66665 2 4.36817 2 3.99998Z"
            fill={color || '#565656'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7.99998C2 7.63179 2.29848 7.33331 2.66667 7.33331H7.33333C7.70152 7.33331 8 7.63179 8 7.99998C8 8.36817 7.70152 8.66665 7.33333 8.66665H2.66667C2.29848 8.66665 2 8.36817 2 7.99998Z"
            fill={color || '#565656'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333H7.33333C7.70152 11.3333 8 11.6318 8 12C8 12.3682 7.70152 12.6666 7.33333 12.6666H2.66667C2.29848 12.6666 2 12.3682 2 12Z"
            fill={color || '#565656'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7447 12.6161C11.6661 12.5836 11.5924 12.5353 11.5285 12.4714L9.52851 10.4714C9.26816 10.2111 9.26816 9.78896 9.52851 9.52861C9.78886 9.26827 10.211 9.26827 10.4713 9.52861L11.3333 10.3905V4.00002C11.3333 3.63183 11.6317 3.33335 11.9999 3.33335C12.3681 3.33335 12.6666 3.63183 12.6666 4.00002V10.3905L13.5285 9.52861C13.7889 9.26827 14.211 9.26827 14.4713 9.52861C14.7317 9.78896 14.7317 10.2111 14.4713 10.4714L12.4713 12.4714M12.4699 12.4728C12.3494 12.5926 12.1833 12.6667 11.9999 12.6667C11.9095 12.6667 11.8233 12.6487 11.7447 12.6161"
            fill={color || '#565656'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1409_1242">
            <rect width="16" height="16" fill={color || '#565656'} />
          </clipPath>
        </defs>
      </>
    ),
    dropdownArrow: (
      <>
        <g clipPath="url(#clip0_1409_466)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.47147 10.9714C8.21112 11.2317 7.78901 11.2317 7.52866 10.9714L3.52866 6.97139C3.33799 6.78073 3.28095 6.49398 3.38414 6.24487C3.48733 5.99575 3.73042 5.83332 4.00006 5.83332L12.0001 5.83332C12.2697 5.83332 12.5128 5.99575 12.616 6.24487C12.7192 6.49398 12.6621 6.78073 12.4715 6.97139L8.47147 10.9714Z"
            fill="#333333"
            fillOpacity="0.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_1409_466">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </>
    ),
  }

  return (
    <>
      {id && (
        <SvgIcon
          className={className}
          viewBox={`${vLeft} ${vTop} ${vWidth} ${vHeight}`}
          style={{
            width,
            height,
            transform,
            margin,
            verticalAlign: 'middle',
            color,
            cursor: hover ? 'pointer' : 'default',
            '&:hover': {
              color: hover ? hoverColor || color : color,
            },
          }}>
          {mapping[id]}
          {/* <path fillRule={fillRule} fill="inherit" d={mapping[id]} /> */}
        </SvgIcon>
      )}
    </>
  )
}

Icons.defaultProps = {
  vLeft: 0,
  vTop: 0,
  vWidth: 24,
  vHeight: 24,
  width: 24,
  height: 24,
  transform: 'inherit',
  hoverColor: false,
  fillRule: 'inherit',
}

Icons.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  transform: PropTypes.string,
  vLeft: PropTypes.any,
  vTop: PropTypes.any,
  vWidth: PropTypes.any,
  vHeight: PropTypes.any,
  margin: PropTypes.any,
  color: PropTypes.any,
  hover: PropTypes.bool,
  hoverColor: PropTypes.any,
  className: PropTypes.string,
  fillRule: PropTypes.any,
}
