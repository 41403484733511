import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import {Button, Consulting, Input} from '../components'
import {convertViewPointPx, getIsViewType} from '../const/common'
import {PageContent} from '../structure'
import clsx from 'clsx'
import {map, floor} from 'lodash-es'

// import {useNavigate} from 'react-router-dom'

const Container = styled.div`
  background: #ffffff;
`
const Banner = styled(Styles.Banner)``
const Title = styled(Styles.BannerTitle)``
const SubTitle = styled(Styles.BannerSubTitle)``
const Content = styled(Styles.PageContent)`
  .content span {
    color: ${Colors.black_50};
    font-weight: 400;
    text-align: center;
  }

  @media all and (min-width: 641px) {
    .helperText {
      font-size: ${convertViewPointPx(14)}px;
      margin-top: ${convertViewPointPx(20)}px;
    }
    .settlementWrap {
      font-size: ${convertViewPointPx(20)}px;
      border-radius: ${convertViewPointPx(16)}px;
      padding: ${convertViewPointPx(24)}px;
      margin-top: ${convertViewPointPx(56)}px;
      box-shadow: 0px 4px 16px 1px rgba(0, 32, 121, 0.1);
      .settlement {
        margin-left: ${convertViewPointPx(16)}px;
      }
      .settlementData {
        font-size: ${convertViewPointPx(32)}px;
        margin-left: ${convertViewPointPx(5)}px;
      }
    }
    .caculator {
      width: ${convertViewPointPx(780)}px;
      margin-top: ${convertViewPointPx(64)}px;
    }
    .content span {
      font-size: ${convertViewPointPx(16)}px;
      line-height: ${convertViewPointPx(24)}px;
      margin-top: ${convertViewPointPx(16)}px;
    }
  }
  @media all and (max-width: 640px) {
    .helperText {
      font-size: ${convertViewPointPx(12)}px;
      margin-top: ${convertViewPointPx(16)}px;
    }
    .settlementWrap {
      font-size: ${convertViewPointPx(14)}px;
      border-radius: ${convertViewPointPx(12)}px;
      padding: ${convertViewPointPx(24)}px;
      margin-top: ${convertViewPointPx(40)}px;
      box-shadow: 0px 4px 16px 1px rgba(0, 32, 121, 0.1);
      .settlement {
        margin-left: ${convertViewPointPx(12)}px;
      }
      .settlementData {
        font-size: ${convertViewPointPx(20)}px;
      }
    }
    .caculator {
      margin-top: ${convertViewPointPx(42)}px;
    }
    .content span {
      font-size: ${convertViewPointPx(12)}px;
      line-height: ${convertViewPointPx(18)}px;
      margin-top: ${convertViewPointPx(8)}px;
    }
  }

  .helperText {
    width: 100%;
    color: ${Colors.black_40};
  }

  .settlementWrap {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: baseline;
    color: ${Colors.black};
    text-align: center;
    & div {
      color: ${Colors.primary};
    }
    .settlementData {
      color: ${Colors.primary};
      font-weight: 700;
    }
  }

  .caculator {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const ContentTitle = styled(Styles.PageContentTitle)``

const TabWrap = styled.div`
  height: ${convertViewPointPx(50)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);
  div.tab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid transparent;
    margin-bottom: -4px;
    height: 100%;
    color: rgba(26, 26, 26, 0.4);
    font-weight: 500;
    &.active {
      font-weight: 700;
      border-color: ${Colors.primary};
      color: ${Colors.primary};
    }

    @media all and (min-width: 641px) {
      width: ${convertViewPointPx(260)}px;
      font-size: ${convertViewPointPx(16)}px;
    }
    @media all and (max-width: 640px) {
      width: auto;
      padding: 0 ${convertViewPointPx(8)}px;
      font-size: ${convertViewPointPx(14)}px;
    }
  }
`

const TABS = [
  {id: 'tab0', name: '사업개요'},
  {id: 'tab1', name: '수익성 계산기'},
  {id: 'tab2', name: 'AI 기반 발전량 예측 솔루션'},
]
function VppView() {
  // const navigate = useNavigate()
  const {vpp} = PageContent
  const [isMobile, setIsMobile] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS[0].id)
  const [capacity, setCapacity] = useState(null)
  const [settlement, setSettlement] = useState(null)

  useEffect(() => {
    setIsMobile(getIsViewType() === 'mobile')
  }, [])

  const handleOnClickTab = (e) => {
    setActiveTab(e.target.id)
  }

  const handleCalculate = () => {
    setSettlement(floor((capacity * 303.8625 + capacity * 30.38625) * 0.8 * 12))
  }

  const renderContents = useMemo(() => {
    const tabContent = vpp.content[activeTab]
    const contents = tabContent && tabContent[isMobile ? 'mobile' : 'web']

    const comp = {
      tab0: (content) => (
        <>
          <div className="contentWrap">
            <div className="content">
              <ContentTitle small>내일의 발전량 예측을 통해 전력을 판매하는</ContentTitle>
              <ContentTitle small color={Colors.primary}>
                새로운 고객참여형 전력거래
              </ContentTitle>
              {content && (
                <img
                  src={content[0]}
                  style={{width: `${convertViewPointPx(isMobile ? 203 : 708)}px`}}
                />
              )}
            </div>
          </div>
          <div className="contentWrap" style={{background: Colors.light_blue}}>
            <div className="content">
              <ContentTitle>인업스의 전력중개사업을 선택하는 이유</ContentTitle>
              <img src={content[1]} />
            </div>
          </div>
          <div className="contentWrap">
            <div className="content">
              <ContentTitle small>추가 수익은 어떻게 나오는지 궁금하신가요?</ContentTitle>
              <img src={content[2]} />
            </div>
          </div>
          <div className="contentWrap" style={{background: 'rgba(251, 251, 251, 1)'}}>
            <div className="content">
              <img src={content[3]} style={{marginTop: 0}} />
            </div>
          </div>
        </>
      ),
      tab1: (content) => {
        return (
          <div className="contentWrap">
            <div className="content">
              <ContentTitle>수익성 계산기</ContentTitle>
              <div>
                <div className="caculator">
                  <Input
                    type="number"
                    line
                    placeholder="설비용량을 입력하세요."
                    height={isMobile ? 44 : 48}
                    onChange={(e) => setCapacity(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleCalculate()
                      }
                    }}
                  />
                  <Button
                    primary
                    fontSize={isMobile ? 14 : 16}
                    margin={[0, 0, 0, 12]}
                    width={isMobile ? 108 : 188}
                    height={isMobile ? 44 : 48}
                    onClick={handleCalculate}>
                    계산하기
                  </Button>
                </div>
                {settlement !== null && (
                  <div className="settlementWrap">
                    연 예상 수익
                    <div className="settlement">
                      약{' '}
                      <span className="settlementData">
                        {!isNaN(settlement) ? settlement.toLocaleString() : '- '}원
                      </span>
                    </div>
                  </div>
                )}
                <div className="helperText">
                  *KPX(전력거래소)와 계약된 발전소 기준이며, PPA(한국전력)계약의 경우 별도문의
                  <br />
                  *해당 정산금은 발전시간, 예측결과, 정산 분배율에 따라 달라질 수 있습니다.
                </div>
              </div>
            </div>
          </div>
        )
      },
      tab2: (content) => {
        return (
          <div className="contentWrap">
            <div className="content">
              <ContentTitle>AI 기반 발전량 예측 솔루션</ContentTitle>
              <span>
                기상예측과 통계, 전력데이터 분석기술로 업계 최고의{isMobile ? <br /> : ' '}발전량
                예측 솔루션을 보유하고 있습니다
              </span>
              {content && (
                <img
                  src={content[0]}
                  style={{width: `${convertViewPointPx(isMobile ? 260 : 819)}px`}}
                />
              )}
            </div>
          </div>
        )
      },
    }

    return comp[activeTab](contents)
  }, [vpp, isMobile, activeTab, capacity, settlement])

  const renderTab = () => {
    return map(TABS, (tab) => {
      return (
        <div
          key={tab.id}
          className={clsx('tab', activeTab === tab.id && 'active')}
          id={tab.id}
          onClick={(e) => handleOnClickTab(e)}>
          {tab.name}
        </div>
      )
    })
  }

  return (
    <Container>
      <Banner src={isMobile ? vpp.banner.mobile : vpp.banner.web}>
        <div className="wrapper">
          <Title>{vpp.title}</Title>
          <SubTitle>{vpp.subTitle}</SubTitle>
          <Button
            primary
            width={isMobile ? 200 : 320}
            height={isMobile ? 49 : 69}
            margin={[isMobile ? 20 : 56, 0, 0, 0]}
            onClick={() => {
              window.location.href = 'mailto:support@inups.kr'
            }}>
            서비스 문의하기
          </Button>
        </div>
      </Banner>
      <TabWrap>{renderTab()}</TabWrap>
      <Content>{renderContents}</Content>
      <Consulting />
    </Container>
  )
}

export default VppView
