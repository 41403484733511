import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import {Button, Consulting} from '../components'
import {convertViewPointPx, getIsViewType} from '../const/common'
import {PageContent} from '../structure'

// import {useNavigate} from 'react-router-dom'

const Container = styled.div`
  background: #ffffff;
`
const Banner = styled(Styles.Banner)``
const Title = styled(Styles.BannerTitle)``
const SubTitle = styled(Styles.BannerSubTitle)``
const Content = styled(Styles.PageContent)``
const ContentTitle = styled(Styles.PageContentTitle)``

function DrmsView() {
  // const navigate = useNavigate()
  const {drms} = PageContent
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(getIsViewType() === 'mobile')
  }, [])

  const renderContents = useMemo(() => {
    const contents = drms.content[isMobile ? 'mobile' : 'web']

    return (
      <>
        <div className="contentWrap">
          <div className="content">
            <ContentTitle>아낀 전기를 전력시장에 판매하는</ContentTitle>
            <ContentTitle color={Colors.primary}>에너지 효율화 솔루션</ContentTitle>
            <img
              src={contents[0]}
              style={{width: isMobile ? `${convertViewPointPx(235)}px` : '100%'}}
            />
          </div>
        </div>
        <div className="contentWrap" style={{background: Colors.light_blue}}>
          <div className="content">
            <ContentTitle>왜 인업스의 수요관리 사업이어야 할까요?</ContentTitle>
            <img src={contents[1]} />
          </div>
        </div>
        <div className="contentWrap">
          <div className="content">
            <ContentTitle>참여 방법 및 수익 분석</ContentTitle>
            <img src={contents[2]} />
            <img src={contents[3]} />
          </div>
        </div>
      </>
    )
  }, [drms, isMobile])

  return (
    <Container>
      <Banner src={isMobile ? drms.banner.mobile : drms.banner.web}>
        <div className="wrapper">
          <Title>{drms.title}</Title>
          <SubTitle>{drms.subTitle}</SubTitle>
          <Button
            primary
            width={isMobile ? 200 : 320}
            height={isMobile ? 49 : 69}
            margin={[isMobile ? 20 : 56, 0, 0, 0]}
            onClick={() => {
              window.location.href = 'mailto:support@inups.kr'
            }}
          >
            서비스 문의하기
          </Button>
        </div>
      </Banner>
      <Content>{renderContents}</Content>
      <Consulting />
    </Container>
  )
}

export default DrmsView
