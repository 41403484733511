import React from 'react'
import {
  MyPage,
  SelectService,
  Home,
  DrmsView,
  VppView,
  ScadaView,
  PvmsView,
  AdminUser,
} from '../pages'

const menu = {
  // /user/~
  userMenu: [
    {id: '0', name: '내 서비스', el: <SelectService />, path: '/user/service'},
    {id: '1', name: '내 정보 관리', el: <MyPage />, path: '/user/myPage'},
  ],
  adminMenu: [{id: '2', name: '사용자 관리', el: <AdminUser />, path: '/user/admin'}],
  noUserMenu: [
    {id: '0', name: '회사 소개', el: <Home />, path: '/'},
    {
      id: '1',
      name: '서비스 소개',
      children: [
        {id: 'drms', name: '수요관리사업', parentId: '1', path: '/drms', el: <DrmsView />},
        {id: 'vpp', name: '전력중개사업', parentId: '1', path: '/vpp', el: <VppView />},
        {id: 'scada', name: '원격감시제어사업', parentId: '1', path: '/scada', el: <ScadaView />},
        {id: 'pvms', name: '모니터링 서비스', parentId: '1', path: '/pvms', el: <PvmsView />},
      ],
    },
  ],
}
export default menu
