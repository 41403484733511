const user = {
  header: [
    {key: 'id', title: '아이디', align: 'center'},
    {key: 'name', title: '이름', align: 'center'},
    {key: 'roleName', title: '유형', align: 'center', filterType: 'select'},
    {key: 'phoneNum', title: '휴대폰번호', align: 'center'},
    {key: 'num', title: '전화번호', align: 'center'},
    {key: 'mail', title: '이메일', align: 'center'},
    {key: 'status', title: '상태', align: 'center', filterType: 'select'},
    {key: 'pvms', title: 'PVMS', align: 'center', filterType: 'select'},
    {key: 'drms', title: 'DRMS', align: 'center', filterType: 'select'},
    {key: 'derms', title: 'DERMS', align: 'center', filterType: 'select'},
    {key: 'lastLoginTimestamp', title: '마지막 로그인 시간', align: 'center'},
  ],
  headerDetail: [
    {key: 'id', title: '아이디', align: 'center'},
    {key: 'pass', title: '비밀번호', align: 'center', filterType: 'password', isEditable: true},
    {key: 'passConfirm', title: '비밀번호 확인', align: 'center', filterType: 'password', isEditable: true},
    {key: 'name', title: '이름', align: 'center', isEditable: true},
    {key: 'phoneNum', title: '휴대폰번호', align: 'center', isEditable: true},
    {key: 'num', title: '전화번호', align: 'center', isEditable: true},
    {key: 'mail', title: '이메일', align: 'center', isEditable: true},
    {key: 'roleId', title: '유형', align: 'center', filterType: 'select', isEditable: true},
    {key: 'status', title: '상태', align: 'center', filterType: 'check', isEditable: true},
    {key: 'pvms', title: 'PVMS', align: 'center', filterType: 'check', isEditable: true},
    {key: 'drms', title: 'DRMS', align: 'center', filterType: 'check', isEditable: true},
    {key: 'derms', title: 'DERMS', align: 'center', filterType: 'check', isEditable: true},
    {key: 'lastLoginTimestamp', title: '마지막 로그인 시간', align: 'center'},
  ],
  headerCreate: [
    {key: 'id', title: '아이디', align: 'center'},
    {key: 'pass', title: '비밀번호', align: 'center', filterType: 'password'},
    {key: 'passConfirm', title: '비밀번호 확인', align: 'center', filterType: 'password'},
    {key: 'name', title: '이름', align: 'center'},
    {key: 'phoneNum', title: '휴대폰번호', align: 'center'},
    {key: 'num', title: '전화번호', align: 'center'},
    {key: 'mail', title: '이메일', align: 'center'},
    {key: 'roleId', title: '유형', align: 'center', filterType: 'select'},
    {key: 'status', title: '상태', align: 'center', filterType: 'check'},
    {key: 'pvms', title: 'PVMS', align: 'center', filterType: 'check'},
    {key: 'drms', title: 'DRMS', align: 'center', filterType: 'check'},
    {key: 'derms', title: 'DERMS', align: 'center', filterType: 'check'},
  ],
  testData: [
    {
      id: 'test3',
      name: '안녕3',
      roleName: '관리자',
      phoneNum: '01011111111',
      num: '0233333333',
      mail: 'test1@naver.com',
      status: '가입완료',
      pvms: true,
      drms: false,
      derms: true,
      lastLoginTimestamp: '2021-09-29 14:40',
    },
  ],
}
export default user
