import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import {Button, Consulting} from '../components'
import {convertViewPointPx, getIsViewType} from '../const/common'
import {PageContent} from '../structure'

// import {useNavigate} from 'react-router-dom'

const Container = styled.div`
  background: #ffffff;
`
const Banner = styled(Styles.Banner)``
const Title = styled(Styles.BannerTitle)``
const SubTitle = styled(Styles.BannerSubTitle)``
const Content = styled(Styles.PageContent)`
  & .contentWrap {
    position: relative;
  }

  & .backgroundLine {
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
    background: linear-gradient(83.44deg, #7cc5ff 0%, #369bec 100%);
  }

  @media all and (min-width: 641px) {
    & .backgroundLine {
      height: ${convertViewPointPx(651)}px;
      top: ${convertViewPointPx(695)}px;
    }
  }
  @media all and (max-width: 640px) {
    & .backgroundLine {
      height: ${convertViewPointPx(742)}px;
      top: ${convertViewPointPx(563)}px;
    }
  }
`
const ContentTitle = styled(Styles.PageContentTitle)`
  & span {
    color: rgba(54, 155, 236, 1);
  }
`

function PvmsView() {
  // const navigate = useNavigate()
  const {pvms} = PageContent
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(getIsViewType() === 'mobile')
  }, [])

  const renderContents = useMemo(() => {
    const contents = pvms.content[isMobile ? 'mobile' : 'web']

    return (
      <>
        <div className="contentWrap">
          <div className="content">
            <div className="backgroundLine"></div>
            <img
              src={contents[0]}
              style={{
                marginTop: 0,
                width: isMobile ? `${convertViewPointPx(235)}px` : `${convertViewPointPx(579)}px`,
              }}
            />
            <img src={contents[1]} style={{marginBottom: `${convertViewPointPx(60)}px`}} />

            <ContentTitle>
              <span>약 800개 이상의 PV 사이트</span>를 대상으로
            </ContentTitle>
            <ContentTitle>
              자체 시스템을 통한 <span>PV 모니터링 서비스</span>를 운영합니다
            </ContentTitle>
          </div>
        </div>
        <div className="contentWrap" style={{background: 'rgba(54, 155, 236, 0.1)'}}>
          <div className="content">
            <ContentTitle small>인업스 모니터링 서비스의 효과를 경험하세요</ContentTitle>
            <img src={contents[2]} />
          </div>
        </div>
      </>
    )
  }, [pvms, isMobile])

  return (
    <Container>
      <Banner
        style={{backgroundColor: 'rgba(38, 36, 80, 1)'}}
        src={isMobile ? pvms.banner.mobile : pvms.banner.web}>
        <div className="wrapper">
          <Title>{pvms.title}</Title>
          <SubTitle>{pvms.subTitle}</SubTitle>
          <Button
            primary
            width={isMobile ? 200 : 320}
            height={isMobile ? 49 : 69}
            margin={[isMobile ? 20 : 56, 0, 0, 0]}
            onClick={() => {
              window.location.href = 'mailto:support@inups.kr'
            }}
          >
            서비스 문의하기
          </Button>
        </div>
      </Banner>
      <Content>{renderContents}</Content>
      <Consulting background="rgba(54, 155, 236, 0.05)" buttonColor="rgba(54, 155, 236, 1)" />
    </Container>
  )
}

export default PvmsView
