import {useEffect, useMemo} from 'react'
import {Api, LoginErrorMessage, Session} from '../const'
import {useNavigate, useLocation} from 'react-router-dom'
import qs from 'qs'

function Logout() {
  const navigate = useNavigate()

  const location = useLocation()

  const errorCode = useMemo(() => {
    return qs.parse(location.search, {ignoreQueryPrefix: true})?.code
  }, [location])

  const logout = () => {
    Api.getInstance().get(Api.url + '/user/logout')
  }

  useEffect(() => {
    logout()
    Session.remove('Token')
    Session.remove('User')
    navigate('/login')

    if (errorCode && LoginErrorMessage[errorCode]) {
      alert(LoginErrorMessage[errorCode])
    }
  }, [errorCode])
}

export default Logout
