import React, {useState, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import md5 from 'md5'
import Colors from '../assets/theme/colors'
import {convertViewPointPx, convertCallNum} from '../const/common'
import {UserInfo} from '../structure'
import {map, join, mapKeys} from 'lodash-es'
import {Button, Input} from '../components'
import {Api, Session} from '../const'
// import {useNavigate} from 'react-router-dom'

const Container = styled(Styles.Container)`
  width: 100%;
  margin: 0;
  flex-direction: column;
  padding: ${convertViewPointPx(24)}px;

  .buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: ${convertViewPointPx(24)}px;

    button {
      padding: 0;
    }
  }

  @media all and (min-width: 641px) {
    padding-top: ${convertViewPointPx(90)}px;
  }
  @media all and (max-width: 640px) {
    padding-top: ${convertViewPointPx(72)}px;
  }
`

const GridWrapContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  & > div {
    max-width: 920px;
    width: 100%;
  }
`

const GridContainer = styled.div`
  display: grid;
  align-items: center;
  background: #ffffff;
  padding: ${convertViewPointPx(24)}px;
  box-shadow: ${join(
      map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )}
    rgba(0, 0, 0, 0.08);
  border-radius: ${convertViewPointPx(20)}px;

  @media all and (min-width: 641px) {
    grid-template-columns: repeat(2, ${convertViewPointPx(400)}px);
    grid-template-rows: repeat(4, 1fr);
    gap: 0px ${convertViewPointPx(80)}px;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(4, 1fr);
    gap: 0px ${convertViewPointPx(24)}px;
  }
`
const GridItemWrap = styled.div`
  display: grid;
  align-items: center;
  height: ${convertViewPointPx(40)}px;

  @media all and (min-width: 641px) {
    grid-template-columns: ${convertViewPointPx(180)}px minMax(${convertViewPointPx(200)}px, auto);
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 50% 50%;
  }

  .title {
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    @media all and (min-width: 641px) {
      font-size: ${convertViewPointPx(14)}px;
    }
    @media all and (max-width: 640px) {
      font-size: ${convertViewPointPx(12)}px;
    }
  }
  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: ${Colors.black};
    @media all and (min-width: 641px) {
      font-size: ${convertViewPointPx(14)}px;
    }
    @media all and (max-width: 640px) {
      font-size: ${convertViewPointPx(12)}px;
    }
  }
`
function MyPage(props) {
  // const navigate = useNavigate()
  const {header} = UserInfo
  const [isModify, setIsModify] = useState(false)

  const [data, setData] = useState({})
  // const [isMobile, setIsMobile] = useState(false)

  const handleCancelModify = () => {
    const user = Session.get('User')
    setData(user)
    setIsModify(false)
  }

  const modifyUser = async () => {
    if (confirm('수정하시겠습니까?')) {
      if (!data.name || data.name.trim() === '') {
        alert('이름을 입력해주세요.')
        return false
      }

      if (data.newPass && data.newPass.trim() !== '') {
        if (!data.oldPass || data.oldPass.trim() === '') {
          alert('현재 비밀번호를 입력해주세요.')
          return false
        }

        if (data.oldPass === data.newPass) {
          alert('현재 비밀번호와 변경 비밀번호가 같습니다.\n새로운 비밀번호를 입력해주세요.')
          return false
        }

        try {
          const passChangeRes = await Api.getInstance().put(Api.url + '/user/' + data.id + '/pw', {
            oldPass: md5(data.oldPass.trim()),
            newPass: md5(data.newPass.trim()),
          })

          if (!passChangeRes.data.result) {
            alert('비밀번호가 변경되지 않았습니다.\n현재 비밀번호를 확인해주세요.')
            return false
          }
        } catch (e) {
          alert('비밀번호가 변경되지 않았습니다.\n현재 비밀번호를 확인해주세요.')
          return false
        }
      }

      if (!data.phoneNum || data.phoneNum.trim() === '') {
        alert('휴대폰번호를 입력해주세요.')
        return false
      }

      // if (!data.num || data.num.trim() === '') {
      //   alert('전화번호를 입력해주세요.')
      //   return false
      // }

      if (!data.mail || data.mail.trim() === '') {
        alert('이메일을 입력해주세요.')
        return false
      }

      if (data.mail.indexOf('@') < 0) {
        alert('이메일형식을 확인해주세요.')
        return false
      }

      const program = []

      mapKeys(data.program, (value, key) => {
        program.push(data.program[key].id)
      })

      try {
        const userInfoChangeRes = await Api.getInstance().put(Api.url + '/user/' + data.id, {
          name: data.name.trim(),
          num: convertCallNum(data.num),
          phoneNum: convertCallNum(data.phoneNum),
          mail: data.mail.replace(/ /g, ''),
          program
        })

        if (userInfoChangeRes.data.result) {
          alert('변경되었습니다.')
          const beforeUser = Session.get('User')
          const afterUser = userInfoChangeRes.data.data
          Session.set('User', {
            ...beforeUser,
            name: afterUser.name,
            num: afterUser.num,
            phoneNum: afterUser.phoneNum,
            mail: afterUser.mail,
          })
        } else {
          alert('사용자 정보가 변경되지 않았습니다.\n관리자에게 문의해주세요.')
          return false
        }
      } catch (e) {
        alert('사용자 정보가 변경되지 않았습니다.\n관리자에게 문의해주세요.')
        return false
      }
      return true
    }
    return false
  }

  // useEffect(() => {
  //   setIsMobile(getIsViewType() === 'mobile')
  // }, [])

  useEffect(() => {
    const user = Session.get('User')
    setData(user)
  }, [])

  const renderDetailInfo = useMemo(() => {
    return map([...header], (item) => {
      if (!item.isElement) {
        if (item.inputType === 'password' && isModify === false) return
        return (
          <GridItemWrap key={item.key}>
            <span className="title">{item.title}</span>
            {isModify && item.isEditable === true ? (
              (() => {
                if (item.inputType === 'password') {
                  return (
                    <Input
                      line
                      type={'password'}
                      height={32}
                      fontSize={12}
                      isPassword={false}
                      autoComplete="on"
                      value={data[item.key] || ''}
                      onChange={(e) => setData({...data, [item.key]: e.target.value})}
                    />
                  )
                } else {
                  return (
                    <Input
                      line
                      height={32}
                      fontSize={12}
                      value={data[item.key] || ''}
                      onChange={(e) => setData({...data, [item.key]: e.target.value})}
                    />
                  )
                }
              })()
            ) : (
              <span className="value">{data[item.key] || '-'}</span>
            )}
          </GridItemWrap>
        )
      }
    })
  }, [header, data, isModify])

  return (
    <Container>
      <GridWrapContainer>
        <GridContainer>{renderDetailInfo}</GridContainer>
        <div className="buttonWrap">
          {isModify && (
            <Button
              secondary
              width={55}
              height={32}
              fontSize={12}
              margin={[0, 8, 0, 0]}
              onClick={handleCancelModify}>
              취소
            </Button>
          )}
          <Button
            primary
            width={55}
            height={32}
            fontSize={12}
            onClick={(e) => {
              if (isModify) {
                modifyUser().then((result) => {
                  if (result) {
                    setIsModify(!isModify)
                  }
                })
              } else {
                setIsModify(!isModify)
              }
            }}>
            {isModify ? '완료' : '수정'}
          </Button>
        </div>
      </GridWrapContainer>
    </Container>
  )
}

export default MyPage
