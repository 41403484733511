import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import Styles from '../assets/theme/styles'
import Colors from '../assets/theme/colors'
// import API from '../const/api'
import md5 from 'md5'
import {Storage, Api, Session} from '../const'
import {LoginErrorMessage} from '../const/code'
import {Button, Checkbox, Input} from '../components'
import {convertViewPointPx, openPopup} from '../const/common'
import {useNavigate} from 'react-router-dom'
import {map, join} from 'lodash-es'
import {Tooltip} from '@material-ui/core'

import Background from '../assets/image/web/login/background.png'
import InupsLogo from '../assets/image/logo/inups.png'

const Container = styled(Styles.Container)`
  ${(props) =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
      padding: 0;
      width: 100%;
      align-items: center;
    `}

  ${(props) =>
    props.login &&
    css`
      @media all and (min-width: 641px) {
        background-image: url(${Background});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        padding: 0 ${convertViewPointPx(120)}px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      @media all and (max-width: 640px) {
        width: 100%;
        // height: 100%;
        padding: 0 ${convertViewPointPx(24)}px;
      }
    `}
`

const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & form {
    width: 100%;
  }
  @media all and (min-width: 641px) {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(8px);
    border-radius: ${convertViewPointPx(20)}px;
    width: ${convertViewPointPx(610)}px;
    height: auto;
    padding: ${join(
      map([0, 147, 80], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )};
  }
  @media all and (max-width: 640px) {
    width: 100%;
    height: 100%;
  }
`

const LogoImg = styled.div`
  width: ${convertViewPointPx(138)}px;
  height: ${convertViewPointPx(75)}px;
  background-image: url(${InupsLogo});
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  margin-bottom: ${convertViewPointPx(72)}px;

  @media all and (min-width: 641px) {
    margin-top: ${convertViewPointPx(141)}px;
  }

  @media all and (max-width: 640px) {
    margin-top: ${convertViewPointPx(74)}px;
  }
`

const UnderLineBtn = styled.div`
  font-weight: 400;
  text-decoration-line: underline;
  color: ${Colors.black_40};
  cursor: pointer;
  font-size: ${convertViewPointPx(14)}px;
  line-height: ${convertViewPointPx(17)}px;
`

const TooltipContents = styled.div`
  padding: 4px 0;
  font-size: ${convertViewPointPx(14)}px;
  line-height: 1.4;
  & > h4 {
    margin: 0 0 4px;
  }
`

const ButtonWrap = styled.div`
  width: 100%;
`

function Login() {
  const navigate = useNavigate()

  const [isSaveId, setIsSaveId] = useState(false)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [id, setId] = useState('')
  const [pass, setPass] = useState('')
  const [isOpen, setOpen] = useState(false)

  const login = () => {
    Api.getInstance()
      .post(Api.url + '/user/login', {id, pass: md5(pass)})
      .then((res) => {
        const token = res.data.data.accessToken
        Session.set('Token', token)
        Session.set('User', res.data.data.user)

        if (isSaveId) {
          Storage.set('saveId', id)
        } else {
          Storage.remove('saveId')
        }

        navigate('/user/service')
      })
      .catch((error) => {
        const errorResponse = error.response.data.data
        alert(LoginErrorMessage[errorResponse.errorCode])
      })
  }

  const enterLogin = (e) => {
    if (e.keyCode === 13) {
      login()
    }
  }

  useEffect(() => {
    const token = Session.get('Token')
    if (token) {
      navigate('/user/service')
    } else {
      const saveId = Storage.get('saveId')
      if (saveId) setId(saveId)
      setIsSaveId(!!saveId)
    }
  }, [])

  return (
    <Container login>
      <LoginWrap>
        <LogoImg></LogoImg>
        <form>
          <Input placeholder="아이디를 입력하세요." onChange={(e) => setId(e.target.value)} />
          <Input
            margin={[16, 0, 16, 0]}
            type={isShowPassword ? 'text' : 'password'}
            placeholder="비밀번호를 입력하세요."
            isPassword={true}
            isShowPassword={isShowPassword}
            setIsShowPassword={() => {
              setIsShowPassword(!isShowPassword)
            }}
            autoComplete="on"
            onChange={(e) => setPass(e.target.value)}
            onKeyUp={enterLogin}
          />
        </form>
        <Container spaceBetween>
          <Checkbox
            id="saveId"
            checked={isSaveId}
            text="아이디 저장"
            onChange={(e) => setIsSaveId(e.target.checked)}
          />
          <UnderLineBtn
            onClick={() => {
              openPopup({
                url: '/help',
                target: 'help',
              })
            }}>
            아이디/비밀번호 찾기
          </UnderLineBtn>
        </Container>

        <Button primary margin={[40, 0, 0, 0]} onClick={login}>
          로그인
        </Button>
        <Tooltip
          open={isOpen}
          placement="bottom"
          title={
            <TooltipContents>
              <h4>[ 인업스 고객센터 ]</h4>
              <div>
                <div>대표전화: 1833-3669</div>
                <div>운영시간: 월~금, 09:00 ~ 17:00</div>
                <div>메일: support@inups.kr</div>
              </div>
            </TooltipContents>
          }>
          <ButtonWrap>
            <Button margin={[16, 0, 0, 0]} onClick={() => setOpen(!isOpen)}>
              가입 문의하기
            </Button>
          </ButtonWrap>
        </Tooltip>
        <Button
          width={248}
          line
          margin={[100, 0, 24, 0]}
          onClick={() => {
            navigate('/')
          }}>
          인업스 홈페이지 바로가기
        </Button>
      </LoginWrap>
    </Container>
  )
}

export default Login
