import React, {useEffect, useState, useCallback} from 'react'
import styled, {css} from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import {convertViewPointPx, getIsViewType} from '../const/common'
import {join, map} from 'lodash-es'
import {Button} from '../components'
import {useNavigate} from 'react-router-dom'
import {isMobile, isAndroid, isIOS} from 'react-device-detect'
import {Session, Api} from '../const'
import {List} from 'immutable'

import Service1 from '../assets/image/icon/service1.png'
import Service2 from '../assets/image/icon/service2.png'
import Service3 from '../assets/image/icon/service3.png'

const Container = styled(Styles.Container)`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  @media all and (min-width: 641px) {
    padding-top: ${convertViewPointPx(90)}px;
  }
  @media all and (max-width: 640px) {
    padding-top: ${convertViewPointPx(72)}px;
  }
`

const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  @media all and (min-width: 641px) {
    justify-content: center;
    align-items: center;

    grid-template-columns: ${join(
      map([380, 380, 380], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )};
    grid-column-gap: ${convertViewPointPx(20)}px;
  }

  @media all and (max-width: 640px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: ${convertViewPointPx(20)}px;
    padding: ${join(
      map([20, 24, 57], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )};
  }
`

const Card = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: ${join(
      map([2, 2, 24], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )}
    rgba(0, 0, 0, 0.06);
  border-radius: ${convertViewPointPx(10)}px;

  display: flex;
  justify-content: space-between;

  & .imgWrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (min-width: 641px) {
    height: ${convertViewPointPx(602)}px;
    padding: ${join(
      map([0, 72, 72], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )};
    flex-direction: column;

    & .imgWrap {
      height: ${convertViewPointPx(278)}px;
      align-items: flex-end;
    }

    & img {
      &.card1 {
        width: ${convertViewPointPx(152)}px;
      }
      &.card2 {
        width: ${convertViewPointPx(107)}px;
      }
      &.card3 {
        width: ${convertViewPointPx(88)}px;
      }
    }
  }

  @media all and (max-width: 640px) {
    padding: ${join(
      map([24, 40], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )};
    & .imgWrap {
      width: ${convertViewPointPx(108)}px;
    }

    & img {
      &.card1 {
        width: ${convertViewPointPx(94)}px;
      }
      &.card2 {
        width: ${convertViewPointPx(69)}px;
      }
      &.card3 {
        width: ${convertViewPointPx(56)}px;
      }
    }
  }
`

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isDisabled &&
    css`
      & span {
        color: ${Colors.black_40} !important;
      }
    `}

  & span {
    display: block;
    line-height: ${convertViewPointPx(30)}px;
    color: ${Colors.black};
    &:first-child {
      font-weight: 700;
      @media all and (min-width: 641px) {
        font-size: ${convertViewPointPx(24)}px;
      }

      @media all and (max-width: 640px) {
        font-size: ${convertViewPointPx(20)}px;
      }
    }
  }

  @media all and (min-width: 641px) {
    margin-top: ${convertViewPointPx(48)}px;
  }

  @media all and (max-width: 640px) {
  }
`
const NoUser = styled.div`
  color: ${Colors.red};

  font-weight: 600;
  line-height: ${convertViewPointPx(28)}px;

  border: 1px solid ${Colors.red};
  border-radius: ${convertViewPointPx(30)}px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (min-width: 641px) {
    width: ${convertViewPointPx(116)}px;
    height: ${convertViewPointPx(36)}px;
    font-size: ${convertViewPointPx(14)}px;
    position: absolute;
    top: ${convertViewPointPx(72)}px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media all and (max-width: 640px) {
    width: ${convertViewPointPx(85)}px;
    height: ${convertViewPointPx(27)}px;
    font-size: ${convertViewPointPx(12)}px;
    margin-bottom: ${convertViewPointPx(8)}px;
  }
`

function SelectService() {
  const navigate = useNavigate()
  const [data, setData] = useState([
    {
      id: 1,
      name: 'PVMS',
      title1: '',
      title2: '',
      img: <img className="card1" src={Service1} />,
      isUser: false,
      url: {
        web: null,
        mobile: null,
      },
      infoUrl: '/pvms',
    },
    {
      id: 2,
      name: 'DRMS',
      title1: '',
      title2: '',
      img: <img className="card2" src={Service2} />,
      isUser: false,
      url: {
        web: null,
        mobile: null,
      },
      infoUrl: '/drms',
    },
    {
      id: 3,
      name: 'DERMS',
      title1: '',
      title2: '',
      img: <img className="card3" src={Service3} />,
      isUser: false,
      url: {
        web: null,
        mobile: null,
      },
      infoUrl: '/vpp',
    },
  ])
  const [isMobileView, setIsMobileView] = useState(false)

  const getUpdateProgram = async (list, user, token) => {
    let temp = List(data)
    await Promise.all(
      list.map((program, programIdx) => {
        temp = temp
          .updateIn([programIdx, 'name'], () => program.name)
          .updateIn([programIdx, 'title1'], () => program.name)
          .updateIn([programIdx, 'title2'], () => program.nameKor)
          .updateIn([programIdx, 'isUser'], () => !!user.program[program.name])
          .updateIn([programIdx, 'url', 'web'], () => program.url.web + '/sso?token=' + token)
          .updateIn([programIdx, 'url', 'mobile'], () => program.url.mobile)
        return program
      }),
    )
    return temp.toJSON()
  }

  const getMyProgram = async () => {
    const allProgramRes = await Api.getInstance().get(Api.url + '/program')
    const allProgramList = allProgramRes.data.data
    const user = Session.get('User')
    const token = Session.get('Token')
    if (user) {
      const newData = await getUpdateProgram(allProgramList, user, token)
      setData(newData)
    }
  }

  useEffect(() => {
    setIsMobileView(getIsViewType() === 'mobile')
    getMyProgram()
  }, [])

  const renderCard = useCallback(() => {
    return map(data, (item, index) => {
      return (
        <Card key={index}>
          {!isMobileView && !item.isUser && <NoUser>미가입 서비스</NoUser>}
          <div className="imgWrap">{item.img}</div>
          <TitleWrap isDisabled={!item.isUser}>
            {isMobileView && !item.isUser && <NoUser>미가입 서비스</NoUser>}
            <span>{item.title1}</span>
            <span>{item.title2}</span>
            <Button
              onClick={() => {
                if (item.isUser) {
                  if (isMobile) {
                    const {ios, android} = item.url.mobile
                    isAndroid && android && window.open(android)
                    if (isIOS && ios) window.location.href = ios
                  } else {
                    item.url.web && window.open(item.url.web)
                  }
                } else {
                  navigate(item.infoUrl)
                }
              }}
              secondary
              margin={isMobileView ? [16, 0, 0, 0] : [89, 0, 0, 0]}
              height={isMobileView ? 40 : 52}
              width={isMobileView ? 120 : 200}>
              {item.isUser ? '이동하기' : isMobileView ? '스토어로 이동' : '상품 소개 바로가기'}
            </Button>
          </TitleWrap>
        </Card>
      )
    })
  }, [data, isMobileView])

  return (
    <Container>
      <GridContainer>{renderCard()}</GridContainer>
    </Container>
  )
}

export default SelectService
