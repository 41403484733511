import styled, {css} from 'styled-components'
import Colors from '../theme/colors'
import {convertViewPointPx} from '../../const/common'

const Contents = styled.div`
  width: 100%;
  // min-width: 360px;

  @media all and (min-width: 641px) {
    width: 100%;
    // min-width: 1220px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;
  ${(props) =>
    props.TabButton &&
    css`
      transform: translate(0, -50%);
    `}

  @media all and (max-width: 640px) {
    max-width: 640px;
  }
`

const Paper = styled.div`
  @media all and (min-width: 641px) {
    max-width: 61.5%;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align};
`

const TabMenu = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media all and (min-width: 641px) {
    max-width: 61.5%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }

  @media all and (max-width: 640px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
`

const Title = styled.div`
  font-weight: bold;

  @media all and (min-width: 641px) {
    font-size: 28px;
  }

  @media all and (max-width: 640px) {
    font-size: 20px;
  }
`

const Line = styled.hr`
  border: 0;

  @media all and (min-width: 641px) {
    background-color: black;
    margin-top: 110px;
    margin-bottom: 0px;
    width: 82px;
    height: 3px;
  }

  @media all and (max-width: 640px) {
    background-color: ${Colors.black};
    margin-top: 40.5px;
    margin-bottom: 8.5vw;
    width: 31px;
    height: 2px;
  }
`

const Diagram = styled.img`
  @media all and (min-width: 641px) {
    display: ${(props) => (props.web ? 'inline' : 'none')};
  }

  @media all and (max-width: 640px) {
    display: ${(props) => (props.mobile ? 'inline' : 'none')};
  }
`

const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  flex: 1;
  box-sizing: border-box;
  border-radius: 12px;

  @media all and (min-width: 641px) {
  }
`

const Banner = styled.div`
  background-color: #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${(props) => {
    return css`
      background-image: url(${props.src});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    `
  }}

  & div.wrapper {
    margin: 0 auto;
    width: 100%;

    @media all and (min-width: 641px) {
      max-width: 61.5%;
    }
  }

  @media all and (min-width: 641px) {
    height: ${convertViewPointPx(520)}px;
  }

  @media all and (max-width: 640px) {
    padding: ${convertViewPointPx(24)}px;
    height: ${convertViewPointPx(390)}px;
  }
`

const BannerTitle = styled.div`
  font-weight: 700;
  color: #ffffff;

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(56)}px;
    line-height: ${convertViewPointPx(67)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(32)}px;
    line-height: ${convertViewPointPx(38)}px;
  }
`
const BannerSubTitle = styled.div`
  font-weight: 400;
  color: #ffffff;

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(20)}px;
    line-height: ${convertViewPointPx(24)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${convertViewPointPx(14)}px;
    line-height: ${convertViewPointPx(17)}px;
  }
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .contentWrap {
    width: 100%;
  }
  & .content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    & img {
      width: 100%;
      z-index: 1;
    }
    @media all and (min-width: 641px) {
      padding: ${convertViewPointPx(150)}px 0;
      // max-width: 1180px;
      max-width: 61.5%;
      & img {
        margin-top: ${convertViewPointPx(100)}px;
      }
    }
    @media all and (max-width: 640px) {
      padding: ${convertViewPointPx(80)}px ${convertViewPointPx(24)}px;
      & img {
        margin-top: ${convertViewPointPx(42)}px;
      }
    }
  }
`
const PageContentTitle = styled.div`
  font-weight: 700;
  color: ${(props) => props.color || Colors.black};
  text-align: center;

  @media all and (min-width: 641px) {
    font-size: ${convertViewPointPx(48)}px;
    line-height: ${convertViewPointPx(67)}px;
  }

  @media all and (max-width: 640px) {
    font-size: ${(props) => convertViewPointPx(props.small ? 18 : 20)}px;
    line-height: ${convertViewPointPx(27)}px;
  }
`

const Styles = {
  Contents,
  Container,
  Paper,
  TabMenu,
  Title,
  Line,
  Diagram,
  Card,
  Banner,
  BannerTitle,
  BannerSubTitle,
  PageContent,
  PageContentTitle,
}

export default Styles
