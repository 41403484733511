const Colors = {
  primary: '#5364FE',
  navy: '#030C38',
  red: '#ED1B24',
  blue: '#6C93FF',
  black: '#1A1A1A',
  grey: '#808080',
  grey_light: '#AEAEAE',
  bg_grey: '#FCFCFF',
  black_40: 'rgba(26, 26, 26, 0.4)',
  black_50: 'rgba(26, 26, 26, 0.5)',
  white_60: 'rgba(255, 255, 255, 0.6)',
  light_blue: 'rgba(108, 147, 255, 0.1)',
}

export default Colors
