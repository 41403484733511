import React, {useEffect, useState, useMemo, useRef} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import {convertViewPointPx} from '../const/common'
import {filter, join, map} from 'lodash-es'
import Icons from './Icons'

const DropboxContainer = styled.div`
  position: relative;
`

const DropboxToggleButton = styled.button`
  border: 1px solid rgba(26, 26, 26, 0.2);
  background: #fff;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0 ${convertViewPointPx(14)}px;
  color: ${Colors.black};
  width: ${(props) => (props.width ? convertViewPointPx(props.width) + 'px' : '100%')};
  height: ${(props) => convertViewPointPx(props.height || 52) + 'px'};
  border-radius: ${convertViewPointPx(10)}px;
  margin: ${(props) => {
    if (props.margin) {
      const marginList = map(props.margin, (i) => convertViewPointPx(i ?? 0) + 'px')
      return join(marginList, ' ')
    } else {
      return 0
    }
  }};
  display: flex;
  align-items: center;
  font-size: ${(props) => convertViewPointPx(props.fontSize || 16)}px;

  span {
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.placeholder {
      color: ${Colors.grey};
    }
  }

  input.dropboxInput {
    cursor: pointer;
    font-weight: 400;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    width: 100%;
    height: 100%;
    color: ${Colors.black};
    font-size: ${(props) => convertViewPointPx(props.fontSize || 16)}px;
    &:placeholder {
      color: ${Colors.grey};
    }
    &:disabled {
      cursor: inherit;
      width: 0;
      height: 0;
      z-index: -9999;
    }
  }
`
const DropboxBody = styled.div`
  width: 100%;
  position: absolute;
  top: calc(100% + ${convertViewPointPx(8)}px);
  left: 0;
  box-sizing: border-box;
  z-index: 99999;
  overflow: overlay;
  background: #ffffff;
  border: 1px solid rgba(51, 51, 51, 0.1);
  box-shadow: ${join(
      map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
      ' ',
    )}
    rgba(0, 0, 0, 0.08);
  border-radius: ${convertViewPointPx(10)}px;
  display: none;
  &.open {
    display: block;
  }
`

const DropboxItem = styled.div`
  width: 100%;
  height: ${(props) => convertViewPointPx(props.height || 52) + 'px'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: ${(props) => convertViewPointPx(props.fontSize || 16)}px;
  color: rgba(51, 51, 51, 0.7);
  cursor: pointer;
  &:hover {
    background: ${Colors.light_blue};
  }
  &.selected {
    background: ${Colors.light_blue};
    color: ${Colors.black};
  }
`

function Dropbox(props) {
  const inputFocus = useRef(null)

  const {height, fontSize, data, placeholder, isSearch, selected, onChange, ...rest} = props

  const [searchText, setSearchText] = useState('')
  const [initData, setInitData] = useState([])
  const [renderData, setRenderData] = useState([])
  const [isOpenBody, setIsOpenBody] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const toggleDropbox = (e) => {
    e.preventDefault()
    setIsOpenBody(!isOpenBody)
    setIsFocus(!isOpenBody)
  }

  const handleOnChange = (e) => {
    if (!e.target.value || e.target.value === '') {
      setSelectedItem(null)
      onChange && onChange(null)
    }
    setSearchText(e.target.value)
    setRenderData(
      filter(initData || [], (item, index) => {
        if (item?.name.indexOf(e.target.value || '') > -1) {
          return true
        } else {
          return false
        }
      }),
    )
  }
  const handleOnClick = (e, item) => {
    setSelectedItem(item)
    setRenderData(initData)
    setIsOpenBody(false)
    onChange && onChange(item)
  }

  useEffect(() => {
    setInitData(data)
  }, [data])

  useEffect(() => {
    setRenderData(initData)
  }, [initData])

  useEffect(() => {
    setSelectedItem(selected)
  }, [selected])

  useEffect(() => {
    setSearchText(selectedItem?.name || '')
  }, [selectedItem])

  useEffect(() => {
    if (isFocus) {
      inputFocus.current?.focus()
    } else {
      inputFocus.current?.blur()
    }
  }, [isFocus])

  const renderBody = useMemo(() => {
    return map(renderData, (item) => {
      return (
        <DropboxItem
          onMouseDown={(e) => handleOnClick(e, item)}
          className={selectedItem?.value === item.value ? 'selected' : ''}
          id={item.id ? item.id : item.value}
          name={item.name}
          height={height}
          fontSize={fontSize}
          key={item.value}>
          {item.name}
        </DropboxItem>
      )
    })
  }, [renderData])

  return (
    <DropboxContainer>
      <DropboxToggleButton
        onClick={(e) => toggleDropbox(e)}
        height={height}
        fontSize={fontSize}
        {...rest}>
        <input
          ref={inputFocus}
          disabled={!isSearch}
          className="dropboxInput"
          value={searchText || ''}
          placeholder={placeholder || ''}
          onBlur={(e) => toggleDropbox(e)}
          onChange={(e) => handleOnChange(e)}
        />
        {!isSearch && (
          <span className={!selected && 'placeholder'}>
            {selectedItem ? selectedItem.name : placeholder}
          </span>
        )}
        <Icons
          hover
          id="dropdownArrow"
          width={convertViewPointPx(16)}
          height={convertViewPointPx(17)}
          vWidth="16"
          vHeight="17"
        />
      </DropboxToggleButton>
      <DropboxBody
        className={isOpenBody ? 'open' : ''}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
        {renderData.length > 0 ? renderBody : <span className="no_match">검색결과가 없습니다</span>}
      </DropboxBody>
    </DropboxContainer>
  )
}

Dropbox.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  placeholder: PropTypes.string,
  isSearch: PropTypes.bool,
  selected: PropTypes.object,
  onChange: PropTypes.func,
}

export default Dropbox
