import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import Styles from './assets/theme/styles'
import {Api, Session} from './const'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {getIsViewType} from './const/common'

import Footer from './components/Footer'
import Header from './components/Header'

const MainContainer = styled(Styles.Container)`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  grid-template-rows: repeat(3, minmax(0, auto));
  height: 100%;
  position: relative;
`

function Contents() {
  const navigate = useNavigate()
  const routerLocation = useLocation()

  const [scrollTop, setScrollTop] = useState(0)
  // const [serviceCurrent, setServiceCurrent] = useState(0)
  const [pathname, setPathname] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const contentsRef = useRef(null)

  useEffect(() => {
    setIsMobile(getIsViewType() === 'mobile')

    // window.addEventListener('scroll', (e) => {
    //   setScrollTop(e.target.scrollingElement.scrollTop)
    // })

    const token = Session.get('Token')
    const user = Api.getInstance().getUser()

    if (token) {
      if (user) {
        Api.getInstance()
          .get(Api.url + '/user/' + user.userId)
          .then((res) => {
            if (res.data.result) {
              Session.set('User', res.data.data)
            }
          })
      } else {
        navigate('/logout')
      }
    }

    return (_) => {
      setScrollTop(0)
    }
  }, [])

  useEffect(() => {
    setPathname(routerLocation.pathname)
  }, [routerLocation])

  useEffect(() => {
    if (contentsRef?.current) {
      contentsRef.current.scrollTop = 0
    }
  }, [pathname])

  return (
    <MainContainer>
      <Header
        isScroll={scrollTop}
        isMobile={isMobile}
        transBg={!!(pathname === '/' || (pathname.indexOf('user') > -1 && isMobile))}
        pathname={pathname}
        style={
          pathname === '/' || (pathname.indexOf('user') > -1 && isMobile)
            ? {
                position:
                  pathname === '/' || (pathname.indexOf('user') > -1 && isMobile)
                    ? 'absolute'
                    : 'relative',
                top: 0,
                ...(!scrollTop && {boxShadow: 'none'}),
              }
            : {}
        }
      />
      <div
        ref={contentsRef}
        onScroll={(e) => {
          setScrollTop(e.target.scrollTop)
        }}
        style={{
          flexGrow: 1,
          height: 0,
          overflow: 'overlay',
        }}>
        <Outlet />
        {pathname.indexOf('user') < 0 && <Footer />}
      </div>
    </MainContainer>
  )
}

export default Contents
