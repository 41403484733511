import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {convertViewPointPx} from '../const/common'
import Colors from '../assets/theme/colors'
import {join, map} from 'lodash-es'

const ButtonContainer = styled.button`
  position: relative;
  padding: 0 ${convertViewPointPx(16)}px;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${Colors.primary};

  width: ${(props) => (props.width ? convertViewPointPx(props.width) + 'px' : '100%')};
  height: ${(props) => convertViewPointPx(props.height || 52) + 'px'};
  margin: ${(props) => {
    if (props.margin) {
      const marginList = map(props.margin, (i) => convertViewPointPx(i ?? 0) + 'px')
      return join(marginList, ' ')
    } else {
      return 0
    }
  }};
  border-radius: ${(props) => convertViewPointPx(props.radius || 10)}px;
  line-height: ${convertViewPointPx(19)}px;

  @media all and (min-width: 641px) {
    background: #fff;
    font-size: ${(props) => convertViewPointPx(props.fontSize || 16)}px;
  }

  @media all and (max-width: 640px) {
    background: rgba(108, 147, 255, 0.1);
    font-size: ${(props) => convertViewPointPx(props.fontSize || 15)}px;
  }

  ${(props) =>
    props.primary &&
    css`
      background: ${Colors.primary} !important;
      color: #fff;
      font-weight: 600;
      font-size: ${(props) => convertViewPointPx(props.fontSize || 16)}px;
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: rgba(108, 147, 255, 0.1) !important;
    `}

    ${(props) =>
    props.line &&
    css`
      border: 1px solid #5364fe;
      backdrop-filter: blur(22px);
      background: #fff !important;
    `}
`

/**
 *
 * @param {width, height, margin([mt,mr,mb,ml])} props
 * @returns
 */
function Button(props) {
  const {children, ...rest} = props
  return <ButtonContainer {...rest}>{children}</ButtonContainer>
}

Button.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
}

export default Button
