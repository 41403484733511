import React, {useState, useMemo} from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'
import Colors from '../assets/theme/colors'
import clsx from 'clsx'
import {convertViewPointPx, convertCallNum} from '../const/common'
import {map, join} from 'lodash-es'
import {Input, Button} from '../components'
import {Api} from '../const'

const Container = styled(Styles.Container)`
  display: flex;
  flex-direction: column;
`

const TabWrap = styled.div`
  width: 100%;
  height: ${convertViewPointPx(50)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);
  div.tab {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid transparent;
    margin-bottom: -4px;
    height: 100%;
    color: rgba(26, 26, 26, 0.4);
    font-weight: 500;
    &.active {
      font-weight: 700;
      border-color: ${Colors.primary};
      color: ${Colors.primary};
    }

    @media all and (min-width: 641px) {
      width: ${convertViewPointPx(260)}px;
      font-size: ${convertViewPointPx(16)}px;
    }
    @media all and (max-width: 640px) {
      width: 50%;
      padding: 0 ${convertViewPointPx(8)}px;
      font-size: ${convertViewPointPx(14)}px;
    }
  }
`

const Content = styled(Styles.PageContent)`
  .content {
    padding: ${convertViewPointPx(16)}px 0;
    width: 100%;
  }

  .content span {
    color: ${Colors.black_50};
    font-weight: 400;
    text-align: left;
  }

  @media all and (min-width: 641px) {
    .content span {
      font-size: ${convertViewPointPx(16)}px;
      line-height: ${convertViewPointPx(24)}px;
    }
  }
  @media all and (max-width: 640px) {
    .content span {
      font-size: ${convertViewPointPx(12)}px;
      line-height: ${convertViewPointPx(18)}px;
    }
  }
`

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: repeat(2, 60px);
  gap: 0px ${convertViewPointPx(24)}px;

  &.result {
    background: rgba(108, 147, 255, 0.1);
    padding: 0 ${convertViewPointPx(24)}px;
    box-shadow: ${join(
        map([0, 5, 16], (i) => convertViewPointPx(i) + 'px'),
        ' ',
      )}
      rgba(0, 0, 0, 0.08);
    border-radius: ${convertViewPointPx(20)}px;

    .title {
      font-size: ${convertViewPointPx(14)}px;
    }

    .value {
      font-size: ${convertViewPointPx(14)}px;
      color: ${Colors.black};
    }
  }
`

const GridItemWrap = styled.div`
  display: grid;
  align-items: center;
  height: ${convertViewPointPx(60)}px;
  grid-template-columns: ${convertViewPointPx(100)}px minMax(${convertViewPointPx(120)}px, auto);

  .title {
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    font-size: ${convertViewPointPx(14)}px;
  }

  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: ${Colors.black};
    font-size: ${convertViewPointPx(14)}px;
  }
`

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: ${convertViewPointPx(16)}px;
`

const ResultContainer = styled.div`
  h3 {
    text-align: center;
    color: ${Colors.black_50};
  }
`

const TABS = [
  {id: 'id', name: '아이디 찾기'},
  {id: 'pass', name: '비밀번호 초기화'},
]

function HelpUserView() {
  const [activeTab, setActiveTab] = useState(TABS[0].id)

  const handleOnClickTab = (e) => {
    setActiveTab(e.target.id)
  }

  const renderTab = () => {
    return map(TABS, (tab) => {
      return (
        <div
          key={tab.id}
          className={clsx('tab', activeTab === tab.id && 'active')}
          id={tab.id}
          onClick={(e) => handleOnClickTab(e)}>
          {tab.name}
        </div>
      )
    })
  }

  const renderContents = useMemo(() => {
    const comp = {
      id: () => <FindId></FindId>,
      pass: () => <FindPass></FindPass>,
    }
    return comp[activeTab]()
  }, [activeTab])

  return (
    <Container>
      <TabWrap>{renderTab()}</TabWrap>
      <Content>{renderContents}</Content>
    </Container>
  )
}

function FindId() {
  const [name, setName] = useState('')
  const [phoneNum, setPhoneNum] = useState('')
  const [code, setCode] = useState('')
  const [isRequestCode, setIsRequestCode] = useState(false)
  const [isFinded, setIsFined] = useState(false)
  const [data, setData] = useState({id: '', name: ''})

  const getCode = () => {
    if (name.trim() === '') {
      alert('이름을 입력해주세요.')
      return
    }

    if (phoneNum.trim() === '') {
      alert('휴대폰번호를 입력해주세요.')
      return
    }

    setIsRequestCode(true)

    Api.getInstance()
      .post(Api.url + '/user/id/code', {
        name,
        phoneNum: convertCallNum(phoneNum),
      })
      .then((res) => {
        if (res.data.result) {
          alert('입력하신 휴대폰번호로 인증코드를 전송했습니다.')
        } else {
          alert('입력하신 내용으로 사용자를 찾을수 없습니다.')
          setIsRequestCode(false)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert('입력하신 내용으로 여러 사용자가 조회되었습니다.\n관리자에게 문의해주세요.')
        } else {
          alert('아이디를 찾지 못했습니다.')
        }
        setIsRequestCode(false)
      })
  }

  const checkCode = () => {
    if (code.trim() === '') {
      alert('인증코드를 입력해주세요.')
      return
    }

    Api.getInstance()
      .post(Api.url + '/user/id', {
        phoneNum: convertCallNum(phoneNum),
        authCode: code,
      })
      .then((res) => {
        if (res.data.result) {
          setIsFined(true)
          setData(res.data.data)
        } else {
          alert('인증코드를 다시 확인해주세요.')
        }
      })
      .catch(() => {
        alert('인증코드를 다시 확인해주세요.')
      })
  }

  return (
    <div className="content">
      {!isFinded ? (
        <GridContainer>
          <GridItemWrap>
            <span className="title">이름</span>
            <Input
              line
              height={36.5}
              fontSize={14}
              value={name}
              disabled={isRequestCode}
              onChange={(e) => setName(e.target.value)}
            />
          </GridItemWrap>
          <GridItemWrap>
            <span className="title">휴대폰번호</span>
            <Input
              line
              height={36.5}
              fontSize={14}
              value={phoneNum}
              disabled={isRequestCode}
              onChange={(e) => setPhoneNum(e.target.value)}
            />
          </GridItemWrap>
          {isRequestCode && (
            <GridItemWrap>
              <span className="title">인증코드</span>
              <Input
                line
                height={36.5}
                fontSize={14}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </GridItemWrap>
          )}
        </GridContainer>
      ) : (
        <ResultContainer>
          <h3>아이디 조회 결과</h3>
          <GridContainer className="result">
            <GridItemWrap>
              <span className="title">아이디</span>
              <span className="value">{data?.id}</span>
            </GridItemWrap>
            <GridItemWrap>
              <span className="title">이름</span>
              <span className="value">{data?.name}</span>
            </GridItemWrap>
          </GridContainer>
        </ResultContainer>
      )}
      {isFinded ? (
        <ButtonWrap>
          <Button
            primary
            width={55}
            height={32}
            fontSize={12}
            margin={[0, 8, 0, 0]}
            onClick={() => window.close()}>
            확인
          </Button>
        </ButtonWrap>
      ) : (
        <ButtonWrap>
          <Button
            secondary
            width={55}
            height={32}
            fontSize={12}
            margin={[0, 8, 0, 0]}
            onClick={() => window.close()}>
            취소
          </Button>
          {isRequestCode ? (
            <Button primary width={55} height={32} fontSize={12} onClick={checkCode}>
              확인
            </Button>
          ) : (
            <Button primary width={100} height={32} fontSize={12} onClick={getCode}>
              사용자 인증
            </Button>
          )}
        </ButtonWrap>
      )}
    </div>
  )
}

function FindPass() {
  const [id, setId] = useState('')
  const [phoneNum, setPhoneNum] = useState('')
  const [code, setCode] = useState('')
  const [isRequestCode, setIsRequestCode] = useState(false)
  const [isFinded, setIsFined] = useState(false)
  const [data, setData] = useState({name: ''})

  const getCode = () => {
    if (id.trim() === '') {
      alert('아이디를 입력해주세요.')
      return
    }

    if (phoneNum.trim() === '') {
      alert('휴대폰번호를 입력해주세요.')
      return
    }

    setIsRequestCode(true)

    Api.getInstance()
      .post(Api.url + '/user/pw/code', {
        id,
        phoneNum: convertCallNum(phoneNum),
      })
      .then((res) => {
        if (res.data.result) {
          alert('입력하신 휴대폰번호로 인증코드를 전송했습니다.')
        } else {
          alert('입력하신 내용으로 사용자를 찾을수 없습니다.')
          setIsRequestCode(false)
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert('입력하신 내용으로 여러 사용자가 조회되었습니다.\n관리자에게 문의해주세요.')
        } else {
          alert('사용자를 찾지 못했습니다.')
        }
        setIsRequestCode(false)
      })
  }

  const checkCode = () => {
    if (code.trim() === '') {
      alert('인증코드를 입력해주세요.')
      return
    }

    Api.getInstance()
      .post(Api.url + '/user/pw/reset', {
        id,
        authCode: code,
      })
      .then((res) => {
        if (res.data.result) {
          setIsFined(true)
          setData(res.data.data)
        } else {
          alert('인증코드를 다시 확인해주세요.')
        }
      })
      .catch(() => {
        alert('인증코드를 다시 확인해주세요.')
      })
  }

  return (
    <div className="content">
      {!isFinded ? (
        <GridContainer>
          <GridItemWrap>
            <span className="title">아이디</span>
            <Input
              line
              height={36.5}
              fontSize={14}
              value={id}
              disabled={isRequestCode}
              onChange={(e) => setId(e.target.value)}
            />
          </GridItemWrap>
          <GridItemWrap>
            <span className="title">휴대폰번호</span>
            <Input
              line
              height={36.5}
              fontSize={14}
              value={phoneNum}
              disabled={isRequestCode}
              onChange={(e) => setPhoneNum(e.target.value)}
            />
          </GridItemWrap>
          {isRequestCode && (
            <GridItemWrap>
              <span className="title">인증코드</span>
              <Input
                line
                height={36.5}
                fontSize={14}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </GridItemWrap>
          )}
        </GridContainer>
      ) : (
        <ResultContainer>
          <h3>비밀번호 초기화되었습니다.</h3>
          <GridContainer className="result">
            <GridItemWrap>
              <span className="title">이름</span>
              <span className="value">{data?.name}</span>
            </GridItemWrap>
          </GridContainer>
        </ResultContainer>
      )}
      {isFinded ? (
        <ButtonWrap>
          <Button
            primary
            width={55}
            height={32}
            fontSize={12}
            margin={[0, 8, 0, 0]}
            onClick={() => window.close()}>
            확인
          </Button>
        </ButtonWrap>
      ) : (
        <ButtonWrap>
          <Button
            secondary
            width={55}
            height={32}
            fontSize={12}
            margin={[0, 8, 0, 0]}
            onClick={() => window.close()}>
            취소
          </Button>
          {isRequestCode ? (
            <Button primary width={55} height={32} fontSize={12} onClick={checkCode}>
              확인
            </Button>
          ) : (
            <Button primary width={100} height={32} fontSize={12} onClick={getCode}>
              사용자 인증
            </Button>
          )}
        </ButtonWrap>
      )}
    </div>
  )
}

export default HelpUserView
