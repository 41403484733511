import {mapKeys, round} from 'lodash-es'

// 반응형 px 계산
export const convertViewPointPx = (px) => {
  const clientWidth = document.documentElement.clientWidth
  // mobile 640 부터 641 부터는 웹 이지만 1600 아래부터는 1600으로 고정
  const type = clientWidth > 640 ? 'web' : 'mobile'
  const resolution = type === 'web' ? 1920 : 375
  let windowWidth = clientWidth
  if (type === 'web') {
    if (clientWidth < 1200) {
      windowWidth = 1200
    } else if (clientWidth > 1920) {
      windowWidth = 1920
    }
  }
  // const windowWidth = type === 'web' && clientWidth < 1600 ? 1600 : clientWidth
  const vw = px * (100 / resolution)
  return round(windowWidth * (vw / 100), 4)
}

export const getIsViewType = () => {
  if (document.documentElement.clientWidth > 640) {
    return 'web'
  } else {
    return 'mobile'
  }
}

export const getRountingMenus = (obj, compareKey) => {
  const getObject = (theObject, result = []) => {
    if (theObject instanceof Array) {
      for (let i = 0; i < theObject.length; i++) {
        getObject(theObject[i], result)
      }
    } else {
      for (const prop in theObject) {
        if (prop === compareKey) {
          if (theObject[prop] !== '') {
            result.push(theObject)
          }
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          getObject(theObject[prop], result)
        }
      }
    }
    return result
  }

  return getObject(obj)
}

export const doCopy = (text) => {
  if (navigator.clipboard) {
    // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('클립보드에 복사되었습니다.')
      })
      .catch(() => {
        alert('복사를 다시 시도해주세요.')
      })
  } else {
    // 2.
    if (!document.queryCommandSupported('copy')) {
      return alert('복사하기가 지원되지 않는 브라우저입니다.')
    }

    // 3.
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.style.top = 0
    textarea.style.left = 0
    textarea.style.position = 'fixed'

    // 4.
    document.body.appendChild(textarea)
    // focus() -> 사파리 브라우저 서포팅
    textarea.focus()
    // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
    textarea.select()
    // 5.
    document.execCommand('copy')
    // 6.
    document.body.removeChild(textarea)
    alert('클립보드에 복사되었습니다.')
  }
}

export const isMobileOs = () => {
  if (navigator.userAgentData) {
    return navigator.userAgentData.mobile
  } else {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  }
}

export const getOs = () => {
  const ua = navigator.userAgent
  let os = navigator.userAgent
  if (ua.match(/Win(dows )?NT 6\.0/)) {
    os = 'Windows Vista'
  } else if (ua.match(/Win(dows )?(NT 5\.1|XP)/)) {
    os = 'Windows XP'
  } else {
    if (ua.indexOf('Windows NT 5.1') !== -1 || ua.indexOf('Windows XP') !== -1) {
      os = 'Windows XP'
    } else if (ua.indexOf('Windows NT 7.0') !== -1 || ua.indexOf('Windows NT 6.1') !== -1) {
      os = 'Windows 7'
    } else if (ua.indexOf('Windows NT 8.0') !== -1 || ua.indexOf('Windows NT 6.2') !== -1) {
      os = 'Windows 8'
    } else if (ua.indexOf('Windows NT 8.1') !== -1 || ua.indexOf('Windows NT 6.3') !== -1) {
      os = 'Windows 8.1'
    } else if (ua.indexOf('Windows NT 10.0') !== -1 || ua.indexOf('Windows NT 6.4') !== -1) {
      os = 'Windows 10'
    } else if (
      ua.indexOf('iPad') !== -1 ||
      ua.indexOf('iPhone') !== -1 ||
      ua.indexOf('iPod') !== -1
    ) {
      os = 'Apple iOS'
    } else if (ua.indexOf('Android' !== -1)) {
      os = 'Android OS'
    } else if (ua.match(/Win(dows )?NT( 4\.0)?/)) {
      os = 'Windows NT'
    } else if (ua.match(/Mac|PPC/)) {
      os = 'Mac OS'
    } else if (ua.match(/Linux/)) {
      os = 'Linux'
    } else if (ua.match(/(Free|Net|Open)BSD/)) {
      os = RegExp.$1 + 'BSD'
    } else if (ua.match(/SunOS/)) {
      os = 'Solaris'
    }
  }
  if (os.indexOf('Windows') !== -1) {
    if (navigator.userAgent.indexOf('WOW64') > -1 || navigator.userAgent.indexOf('Win64') > -1) {
      os += ' 64bit'
    } else {
      os += ' 32bit'
    }
  }

  return os
}

export const convertCallNum = (num) => {
  if (!num) return ''
  const callnum = num.replace(/ /g, '')
  return callnum.replace(/-/g, '')
}

export const openPopup = ({
  url = '',
  target = '_blank',
  width = 400,
  height = 350,
  options = {
    fullscreen: false,
    menubar: false,
    resizable: false,
    scrollbars: false,
    status: false,
    titlebar: false,
  },
}) => {
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  const left = windowWidth / 2 - width / 2
  const top = windowHeight / 2 - height / 2

  options.width = width + 'px'
  options.height = height + 'px'
  options.left = left + 'px'
  options.top = top + 'px'

  let opt = ''
  mapKeys(options, (value, key) => {
    if (opt !== '') opt += ','
    opt += key + '=' + value
  })

  window.open(url, target, opt, false)
}
